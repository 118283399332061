import {NONE,FETCH_ACTIVITYTYPE_SUCCESS,FETCH_ACTIVITYTYPE_ERROR} from "../../constants.js";
import {db} from "../../components/firebase";

export const fetchActivityTypeSuccess = (activityType)=>({
    type:FETCH_ACTIVITYTYPE_SUCCESS,
    activityType,
});

export const fetchActivityTypeFailure = (error)=>({
    type:FETCH_ACTIVITYTYPE_ERROR,
    error,
});

export const fetchActivityType = ()=>{
    return (dispatch) => {
        db.collection("activityType")
        .orderBy("name")
        .get()
        .then(function(querySnapshot) {
            var arActivityType=[];
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                arActivityType.push({id:doc.id,...doc.data()});
            });
            dispatch(fetchActivityTypeSuccess(arActivityType));
        })
        .catch(function(error) {
            dispatch(fetchActivityTypeFailure(error));
        });
    }
}