import {NONE,FETCH_RUNNERS_BEGIN,FETCH_RUNNERS_SUCCESS,FETCH_RUNNERS_ERROR,FETCH_ONE_RUNNER_BEGIN,FETCH_ONE_RUNNER_ERROR,FETCH_ONE_RUNNER_SUCCESS,ADD_CONNECTED,REMOVE_CONNECTED,SET_NUM_OF_RUNNERS_TO_RETRIEVE,DECREASE_ONE_FROM_NUM_OF_RUNNERS_TO_RETRIEVE,REPLACE_RUNNER} from "../../constants.js";
import {db} from "../../components/firebase";
import store from "../store/configureStore";

export const fetchRunnersBegin = ()=>({
    type:FETCH_RUNNERS_BEGIN,
});

export const fetchRunnersSuccess = (runners)=>({
    type:FETCH_RUNNERS_SUCCESS,
    runners,
});

export const fetchOneRunnerSuccess = (runner)=>({
    type:FETCH_ONE_RUNNER_SUCCESS,
    runner,
});
export const fetchOneRunnerBegin = ()=>({
    type:FETCH_ONE_RUNNER_BEGIN,
});
export const fetchOneRunnerError = ()=>({
    type:FETCH_ONE_RUNNER_ERROR,
});
export const replaceRunner = (runner)=>({
    type:REPLACE_RUNNER,
    runner,
});

export const setNumOfRunnersToRetrieve = (numOfRunnersToRetrieve)=>({
    type:SET_NUM_OF_RUNNERS_TO_RETRIEVE,
    numOfRunnersToRetrieve,
});
export const decreaseOneFromNumOfRunnersToRetrieve=()=>({
    type:DECREASE_ONE_FROM_NUM_OF_RUNNERS_TO_RETRIEVE,
});

export const fetchRunnersFailure = (error)=>({
    type:FETCH_RUNNERS_ERROR,
    error,
});

export const addConnected = (connectedId)=>({
    type:ADD_CONNECTED,
    connectedId
})
export const removeConnected = (connectedId)=>({
    type:REMOVE_CONNECTED,
    connectedId
})

export const fetchOneRunner = (runnerId,bDecrease)=>{
    return (dispatch) => {
        dispatch(fetchOneRunnerBegin());
        if (store.getState().runners.runners.filter((oRunner)=>oRunner.id===runnerId).length===0){
            db.collection("runners").doc(runnerId)
            .get()
            .then(function(doc) {
                if (doc.exists){
                    const oRunner={id:doc.id,...doc.data()};
                    dispatch(fetchOneRunnerSuccess(oRunner));
                    if (bDecrease)dispatch(decreaseOneFromNumOfRunnersToRetrieve());
                }
            })
            .catch(function(error) {
                console.log ("error in fetch runner. err is "+error);
                dispatch(fetchOneRunnerError());
            });
        }
        else{
            dispatch(fetchOneRunnerSuccess(null));
        }
    }
}

export const fetchRunners = (cityId)=>{
    return (dispatch) => {
        dispatch(fetchRunnersBegin());
        db.collection("runners").where ("cityId","==",cityId)
        .orderBy("name")
        .get()
        .then(function(querySnapshot) {
            var arRunners=[],ii;
            querySnapshot.docs.forEach(doc => {
                // doc.data() is never undefined for query doc snapshots
                let oDoc={id:doc.id,...doc.data()};
                arRunners.push(oDoc);
            });
            dispatch(fetchRunnersSuccess(arRunners));
        })
        .catch(function(error) {
            console.log ("error is"+error);
            dispatch(fetchRunnersFailure(error));
        });
    }
}