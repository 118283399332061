import {ACTIVITY_MAKER_PAGE,ACTIVITY_PAGE,RUNNER_PAGE,FETCH_ONE_ACTIVITY_SUCCESS} from "../constants.js";
import React,{Component} from "react";
import {connect} from "react-redux";
import Calendar from "react-calendar"
import Helper from "../helper";
import {setPage,setSelectedRunnerId,setSelectedScheduledActivityId} from "../redux/actions/state";
import {fetchOneRunner} from "../redux/actions/runners";
import {replaceActivity,fetchOneActivity} from "../redux/actions/activities";
import {db} from "./firebase";
import firebase from "./firebase";

class ActivitiesScheduler extends Component{
    constructor(props){
        super(props);

        this.state={
            date:new Date(),
            emptyMsg:"empty",
            activityDuration:0===1 ? Math.ceil(parseFloat(Helper.fetchActivityById(this.props.selectedActivityId).time)) : 2,
            oScheduledAct:{},
            activeStartDate:new Date(),
        }

        this.hoursJournalRef=React.createRef();
        this.reserveWrapperPrototypeRef=React.createRef();
        this.calendarRef=React.createRef();

        this.onChange=this.onChange.bind(this);
        this.addActivity=this.addActivity.bind(this);
        this.removeActivity=this.removeActivity.bind(this);
        this.showAddedActivity=this.showAddedActivity.bind(this);
        this.refreshMarkedTilesInCalendar=this.refreshMarkedTilesInCalendar.bind(this);
        this.chkIfAddIsLegal=this.chkIfAddIsLegal.bind(this);
        this.setActiveStartDate=this.setActiveStartDate.bind(this);
        this.save=this.save.bind(this);
        this.back=this.back.bind(this);
        this.reserveActivity = this.reserveActivity.bind(this);
        this.sendMsg = this.sendMsg.bind(this);
        this.componentDidMount_cont=this.componentDidMount_cont.bind(this);
        this.openSelectedDateInCalendar=this.openSelectedDateInCalendar.bind(this);
    }
    render(){
        return (
            <div id="eActivitiesScheduler">
                <div id="eCalendarWrapper">
                    <Calendar id="eCalendar" onChange={this.onChange} ref={this.calendarRef} value={this.state.date} onActiveDateChange={({activeStartDate}) => this.setActiveStartDate(activeStartDate)}/>
                    <div id="eHoursJournalControlPanel">
                        <div className="btn markBtn1" onClick={this.back}>back</div>
                    </div>
                </div>
                <div id="eHoursJournalWrapper">
                    <div id="eHoursJournal" ref={this.hoursJournalRef}>
                        <div id="eEntryWrapperPrototype" className="entryWrapper hideMe"><div className="hours">08:00 - 09:00</div><div className="activityWrapper"><span className="activity">{this.state.emptyMsg}</span><span className="numOfRsv"></span></div><button className="bAdd" onClick={this.addActivity}>Add</button><button className="bRemove hideMe">Remove</button></div>
                        <div id="eReserveWrapperPrototype" className="reserveWrapper hideMe" ref={this.reserveWrapperPrototypeRef}>
                            <div className="rsvDate"></div>
                            <div className="rsvTime"></div>
                            <div className="rsvByYou hideMe"><span>you reserved</span>&nbsp;<span className="numOfSpotsReserved"></span>&nbsp;<span className="oneOrMany">spot</span></div>
                            <div className="rsvRequestsByYou hideMe"><span>you have pending reservation requests for </span>&nbsp;<span className="numOfSpotsReserved"></span>&nbsp;<span className="oneOrMany">spot</span></div>
                            <div className="rsvByOthers hideMe"><span className="joinOrJoining">join</span>&nbsp;<span className="numOfSpotsReserved"></span>&nbsp;other&nbsp;<span className="oneOrMany">participant</span></div>
                            <div>
                                <span>reserve:</span>&nbsp;<select className="rsvReserve"><option value="-1">--- choose ---</option><option value="0">0</option></select>
                                <div className="btn bAdd hideMe">reserve</div><div className="btn bUpdate hideMe">update</div><div className="unavailableActivityCaption hideMe">unavailable</div>
                            </div>
                        </div>
                        <div id="eInterestingActivities">
                            <label className="caption"></label>
                        </div>                    
                        <div id="eActivitiesToDt">
                            <label className="caption"></label>
                        </div>                    
                    </div>
                </div>
            </div>
        )
    }
    resetHoursJournal(){
        if (this.props.type!=="choose"){
            document.getElementById("eHoursJournal").querySelectorAll(".entryWrapper:not(#eEntryWrapperPrototype)").forEach((elm)=>{
                elm.querySelector(".activity").innerText=this.state.emptyMsg;
                elm.querySelector(".bAdd").classList.remove("hideMe");
                elm.querySelector(".bRemove").classList.add("hideMe");
                if (this.state.date.getTime()>(new Date()).getTime()){
                    elm.querySelector(".bAdd").removeAttribute("disabled");
                    elm.querySelector(".bRemove").removeAttribute("disabled");
                }
                else{
                    elm.querySelector(".bAdd").setAttribute("disabled","true");
                    elm.querySelector(".bRemove").setAttribute("disabled","true");
                }
                elm.querySelector(".numOfRsv").innerHTML="";
                elm.classList.remove("submerged","markTopComponent","markMiddleComponents","markBottomComponent");
            });
        }
        else{
            document.querySelector("#eHoursJournal>#eInterestingActivities>.caption").innerHTML="";
            document.querySelector("#eHoursJournal>#eActivitiesToDt>.caption").innerHTML="";
            [...document.querySelectorAll(".scheduledAct")].forEach((elm)=>{
                elm.remove();
            });
        }

    }
    onChange(date){
        this.setState({
            date,
        });
    }
    showAddedActivity(eNode){
        var jj;
        const sText=this.props.selectedActivityId ? Helper.fetchActivityById(this.props.selectedActivityId).name : "what a fuckup i made with all of this";
        eNode.querySelector(".activity").innerText=sText;
        eNode.querySelector(".bAdd").classList.add("hideMe");
        eNode.querySelector(".bRemove").classList.remove("hideMe");
        eNode.classList.add("markTopComponent");
        for (jj=1;jj<this.state.activityDuration;jj++){
            eNode=eNode.nextElementSibling;
            eNode.classList.add("submerged");
            eNode.classList.remove("markTopComponent");
            if (jj+1===this.state.activityDuration){
                eNode.classList.add("markBottomComponent");
            }
            else{
                eNode.classList.add("markMiddleComponent");
            }
        }
    }
    addActivity(e){
        var oDt=new Date(this.state.date.getTime()),arNew;
        oDt.setHours(e.target.parentNode.querySelector(".hours").getAttribute("data-start"));
        oDt.setMinutes(0);
        oDt.setSeconds(0);
        oDt.setMilliseconds(0);
        if (this.chkIfAddIsLegal(oDt)){
/*
            arNew=this.state.arScheduledAct.slice();
            arNew.push({actId:this.props.selectedActivityId,dt:oDt});
            this.showAddedActivity(e.target.parentNode);
            this.setState({
                arScheduledAct:arNew,
            });
*/
            let oNewObj={dt:oDt,},sNewProp=oDt.getTime();
            db.collection("activities").doc(this.props.selectedActivityId).update({
                ["oScheduledAct."+sNewProp]:oNewObj,
            })
            .then(()=>{
                this.setState((prevState,prevProps)=>{
                    let oUpdatedScheduledAct=prevState.oScheduledAct;
                    oUpdatedScheduledAct[sNewProp]=oNewObj;
                    return {oScheduledAct:oUpdatedScheduledAct,};
                })
            })
            .catch((oErr,sErr1,sErr2)=>{
                console.log ("err when writing new schedule for activity to db, in addActivity func.err is - %s %s",sErr1,sErr2);
            })
            .finally(()=>{
            })
        }
    }
    chkIfAddIsLegal(oDt){
        var sProp,bRtVl=true; 
        for (sProp in this.state.oScheduledAct){
            if (
                    this.state.oScheduledAct[sProp].dt.getFullYear()===oDt.getFullYear() && 
                    this.state.oScheduledAct[sProp].dt.getMonth()===oDt.getMonth() && 
                    this.state.oScheduledAct[sProp].dt.getDate()===oDt.getDate() && 
                    (
                        (oDt.getHours()>=this.state.oScheduledAct[sProp].dt.getHours() &&
                        oDt.getHours()<this.state.oScheduledAct[sProp].dt.getHours()+this.state.activityDuration) ||
                        (oDt.getHours()+this.state.activityDuration>this.state.oScheduledAct[sProp].dt.getHours() &&
                        oDt.getHours()+this.state.activityDuration<=this.state.oScheduledAct[sProp].dt.getHours()+this.state.activityDuration)
                    )   
            ){
                bRtVl=false;
                break;
            }
        }
        return bRtVl;
    }
    chkIfAddIsLegal_old(oDt){
        var jj,bRtVl=true; 
        for (jj=0;jj<this.state.arScheduledAct.length;jj++){
            if (
                    this.state.arScheduledAct[jj].dt.getFullYear()===oDt.getFullYear() && 
                    this.state.arScheduledAct[jj].dt.getMonth()===oDt.getMonth() && 
                    this.state.arScheduledAct[jj].dt.getDate()===oDt.getDate() && 
                    (
                        (oDt.getHours()>=this.state.arScheduledAct[jj].dt.getHours() &&
                        oDt.getHours()<this.state.arScheduledAct[jj].dt.getHours()+this.state.activityDuration) ||
                        (oDt.getHours()+this.state.activityDuration>this.state.arScheduledAct[jj].dt.getHours() &&
                        oDt.getHours()+this.state.activityDuration<=this.state.arScheduledAct[jj].dt.getHours()+this.state.activityDuration)
                    )   
            ){
                bRtVl=false;
                break;
            }
        }
        return bRtVl;
    }
    removeActivity(e){
        var jj,eNode=e.target.parentNode,arNew;
        e.target.parentNode.querySelector(".activity").innerText=this.state.emptyMsg;
        e.target.classList.add("hideMe");
        e.target.parentNode.querySelector(".bAdd").classList.remove("hideMe");
        eNode.classList.remove("markTopComponent");
        for (jj=1;jj<this.state.activityDuration;jj++){
            eNode=eNode.nextElementSibling;
            eNode.classList.remove("submerged","markMiddleComponents","markBottomComponent");
        }
        var oDt=new Date(this.state.date.getTime());
        oDt.setHours(e.target.parentNode.querySelector(".hours").getAttribute("data-start"));
        oDt.setMinutes(0);
        oDt.setSeconds(0);
        oDt.setMilliseconds(0);
/*
        arNew=this.state.arScheduledAct.slice();
        for (jj=0;jj<arNew.length;jj++){
            if (arNew[jj].dt.getTime()===oDt.getTime()){
                arNew.splice(jj,1);
                break;
            }
        }
        this.setState({
            arScheduledAct:arNew,
            date:oDt,
        })
*/
            let sNewProp=oDt.getTime();
            db.collection("activities").doc(this.props.selectedActivityId).update({
                ["oScheduledAct."+sNewProp]: firebase.firestore.FieldValue.delete(),
            })
            .then(()=>{
            })
            .catch((oErr,sErr1,sErr2)=>{
                console.log ("err when writing new schedule for activity to db, in addActivity func.err is - %s %s",sErr1,sErr2);
            })
            .finally(()=>{
            })
            this.setState((prevState,prevProps)=>{
                let oScheduledAct = prevState.oScheduledAct;
                delete oScheduledAct[sNewProp];
                return ({
                    oScheduledAct,
                });
            })

    }
    refreshMarkedTilesInCalendar_obsolete(){
        let jj=0,kk=0,oDtInTile;
        document.getElementById("eCalendarWrapper").querySelectorAll(".react-calendar__tile").forEach((elm)=>{
            elm.classList.remove("markTile");
        });
        for (jj=0;jj<this.state.arScheduledAct.length;jj++){
            document.getElementById("eCalendarWrapper").querySelectorAll(".react-calendar__tile:not(.react-calendar__month-view__days__day--neighboringMonth)").forEach((elm)=>{
                oDtInTile=new Date(this.state.activeStartDate.getTime());
                oDtInTile.setDate(elm.innerText);
                if (oDtInTile.getFullYear()===this.state.arScheduledAct[jj].dt.getFullYear() &&
                    oDtInTile.getMonth()===this.state.arScheduledAct[jj].dt.getMonth() && 
                    oDtInTile.getDate()===this.state.arScheduledAct[jj].dt.getDate()){
                    elm.classList.add("markTile");
                    return false;
                }
            });
        }
    }
    refreshMarkedTilesInCalendar(){
        let sProp,oDtInTile;
        document.getElementById("eCalendarWrapper").querySelectorAll(".react-calendar__tile").forEach((elm)=>{
            elm.classList.remove("markTile");
        });
        for (sProp in this.state.oScheduledAct){
            document.getElementById("eCalendarWrapper").querySelectorAll(".react-calendar__tile:not(.react-calendar__month-view__days__day--neighboringMonth)").forEach((elm)=>{
                oDtInTile=new Date(this.state.activeStartDate.getTime());
                oDtInTile.setDate(elm.innerText);
                if (oDtInTile.getFullYear()===this.state.oScheduledAct[sProp].dt.getFullYear() &&
                    oDtInTile.getMonth()===this.state.oScheduledAct[sProp].dt.getMonth() && 
                    oDtInTile.getDate()===this.state.oScheduledAct[sProp].dt.getDate()){
                    elm.classList.add("markTile");
                    return false;
                }
            });
        }
    }
    setActiveStartDate(dt){
        this.setState({
            activeStartDate:new Date(dt.getTime()),
        });
    }
    save(sSchedActId,sUserId,iRsvNum,iRsvStatus){
        if (iRsvStatus===1 && this.state.oScheduledAct[sSchedActId][sUserId] && this.state.oScheduledAct[sSchedActId][sUserId].rsvStatus===2 && this.state.oScheduledAct[sSchedActId][sUserId].num>iRsvNum)iRsvStatus=2;//reducing num of spots reserved doesn't require or needs approval, from the registered end perspective. it's a monologue.(04/01/21 - right. a monologue. but only if the original reservation was approved and is not in a request mode). 
        let oNewObj={id:sUserId,num:iRsvNum,rsvStatus:iRsvStatus};
        db.collection("activities").doc(this.props.selectedActivityId).update({
            ["oScheduledAct."+this.state.oScheduledAct[sSchedActId].dt.getTime()+"."+sUserId]:oNewObj,
        })
        .then(()=>{
            db.collection("activities").doc(this.props.selectedActivityId)
            .get()
            .then((oSnapshot)=>{
                const oActivityReservations=oSnapshot.data().oScheduledAct;
                let iNumOfReservations=0;
                for (let sProp in oActivityReservations[sSchedActId]){
                    if (!!oActivityReservations[sSchedActId][sProp].rsvStatus && oActivityReservations[sSchedActId][sProp].rsvStatus===2) {
                        iNumOfReservations+=parseInt(oActivityReservations[sSchedActId][sProp].num,10);
                    }
                }
                if (iNumOfReservations>parseInt(oSnapshot.data()["maxNumOfParticipants"],10)){
                        oNewObj.num=this.state.oScheduledAct[sSchedActId][sUserId].num;
                        oNewObj.rsvStatus=1;
                        db.collection("activities").doc(this.props.selectedActivityId).update({
                            ["oScheduledAct."+this.state.oScheduledAct[sSchedActId].dt.getTime()+"."+sUserId]:oNewObj,
                        })
                        .then(()=>{
                            if (iRsvStatus===1){
                                alert ("the spots were taken. sorry.");
                            }
                            else{
                                alert ("you can't accept the registration request at this time. there are not enough free spots left.");
                            }
                            let oUpdatedScheduledAct=this.state.oScheduledAct;
                            for (let sProp in oActivityReservations[sSchedActId]){
                                if (sProp!=="dt" && oActivityReservations[sSchedActId][sProp].id!==sUserId){
                                    oUpdatedScheduledAct[sSchedActId][sProp]=oActivityReservations[sSchedActId][sProp];
                                }
                            }
                            this.setState({oScheduledAct:oUpdatedScheduledAct});
                        })
                        .catch((sErr)=>{
                        })
                        .finally(()=>{
                        });
                }else{
                    const oActivity=Helper.fetchActivityById(this.props.selectedActivityId);
                    const oDt=this.state.oScheduledAct[sSchedActId].dt;
                    let sMsg,sRecipient,sMsgType="";
                    switch (iRsvStatus){
                        case 0:
                            sMsg="your registration request to the '"+oActivity.name+"' activity for the "+(oDt.getMonth()+1)+"/"+oDt.getDate()+"/"+oDt.getFullYear()+ "(MM/DD/YYYY) at "+oDt.getHours()+":00."+" has been rejected";
                            sRecipient=sUserId;
                            sMsgType="reg_rejected";
                            break;
                        case 1:
                            sMsg="there is a registration request to the '"+oActivity.name+"' activity for the "+(oDt.getMonth()+1)+"/"+oDt.getDate()+"/"+oDt.getFullYear()+ "(MM/DD/YYYY) at "+oDt.getHours()+":00.";
                            sRecipient=oActivity.initiator;
                            sMsgType="reg_request";
                            break;
                        case 2:
                            if (iRsvNum===0){
                                sMsg="your request to cancel your reservation to the "    
                            }
                            else if (this.state.oScheduledAct[sSchedActId][sUserId].num>iRsvNum){
                                sMsg="your request to reduce the number of spots reserved to the ";
                            }
                            else{
                                sMsg="your registration request to the ";
                            }
                            sMsg+="'"+oActivity.name+"' activity for the "+(oDt.getMonth()+1)+"/"+oDt.getDate()+"/"+oDt.getFullYear()+ "(MM/DD/YYYY) at "+oDt.getHours()+":00."+" has been accepted";
                            sRecipient=sUserId;
                            sMsgType="reg_accepted";
                            break;
                    }
                    this.sendMsg(sMsg,sRecipient,this.props.selectedActivityId,sSchedActId,sMsgType);
    
                    this.setState((prevState,prevProps)=>{
                        let oUpdatedScheduledAct=prevState.oScheduledAct;
                        oUpdatedScheduledAct[sSchedActId][sUserId]=oNewObj;
                        return {oScheduledAct:oUpdatedScheduledAct,};
                    })
                }
            })
            .catch((sErr)=>{
            
            })
            .finally(()=>{

            });
        })
        .catch((oErr,sErr1,sErr2)=>{
            alert ("the save operation didn't succeed. maybe you should try again alittle bit later on");
            console.log ("err when writing new schedule for activity to db, in addActivity func.err is - %s %s",sErr1,sErr2);
        })
        .finally(()=>{
        })

    }
    save_old(e){

        let oActivity=Helper.fetchActivityById(this.props.selectedActivityId),bIsFound=false,jj;
        if (this.props.type!=="choose"){
            oActivity.scheduledAct=this.state.arScheduledAct;
        }
        else{
            const iIndexInList=e.target.parentNode.parentNode.getAttribute("data-indexInList");
            let oScheduledAct=this.state.arScheduledAct[iIndexInList];

            // the index in array is the identifier of the scheduled activity in the original object. 
            // but what if the owner just deleted a scheduled activity, then the id is no longer safe, 
            // the same scheduled activity could be now in an entirely different place.
            oActivity.scheduledAct[iIndexInList].arReservations.forEach((elm,jj)=>{
                if (elm.id===this.props.userId){
                    elm.num=e.target.parentNode.parentNode.querySelector(".rsvReserve").value;
                    bIsFound=true;
                    return false;
                }
            })
            if (!bIsFound)oActivity.scheduledAct[iIndexInList].arReservations.push({id:this.props.userId,num:e.target.parentNode.parentNode.querySelector(".rsvReserve").value})


        }

        db.collection("activities").doc(this.props.selectedActivityId).set(oActivity)
        .then((docRef)=>{ 
            //docRef = id of new doc
                this.props.replaceActivity(oActivity);
                alert ("the scheduled activities were successfully saved.");
        })
        .catch((error)=>{
            console.log("error when trying to edit activity details via the db. error is - "+error);
        })
        .finally(()=>{
            this.props.setPage(this.props.prevPg,false);
        });
    }
    back(e){
        e.stopPropagation();
        this.props.setPage(this.props.type==="choose" ? ACTIVITY_PAGE : ACTIVITY_MAKER_PAGE,false);
    }
    componentDidMount(){
        if (!!Helper.fetchActivityById(this.props.selectedActivityId)){
            this.componentDidMount_cont();
        }
        else{
            this.props.fetchOneActivity(this.props.selectedActivityId);
            this.setState({
                bIsContMounting:true,
            });
        }
    }
    componentDidMount_cont(){
        var jj,eNode,arNew=[];
        if (this.props.type!=="choose"){
            for (jj=5;jj<=21;jj+=1){
                eNode=document.getElementById("eEntryWrapperPrototype").cloneNode(true);
                eNode.querySelector(".hours").setAttribute("data-start",jj);
                eNode.removeAttribute("id");
                eNode.classList.remove("hideMe");
                eNode.querySelector(".hours").innerText=jj.toString()+":00"+" - "+(jj+1).toString()+":00";
                
                eNode.querySelector(".bAdd").addEventListener("click",(e)=>{
                    this.addActivity(e);
                });
                eNode.querySelector(".bRemove").addEventListener("click",(e)=>{
                    this.removeActivity(e);
                });
                this.hoursJournalRef.current.appendChild(eNode);
            }
        }
        if (Helper.fetchActivityById(this.props.selectedActivityId) && Helper.fetchActivityById(this.props.selectedActivityId).oScheduledAct){
            let oScheduledAct=Helper.fetchActivityById(this.props.selectedActivityId).oScheduledAct;
            for (let sID in oScheduledAct){
                if (!oScheduledAct[sID].dt.getTime)oScheduledAct[sID].dt=oScheduledAct[sID].dt.toDate();
            }
            for (let sProp in oScheduledAct){
                for (let sId in oScheduledAct[sProp]){
                    if (sId!=="dt"){
                        this.props.fetchOneRunner(sId);
                    }
                }
            }
            this.setState({
                oScheduledAct,
            });
        }
        document.getElementById("eMain").scrollTo(0,0);
        /*
        if (Helper.fetchActivityById(this.props.selectedActivityId) && Helper.fetchActivityById(this.props.selectedActivityId).scheduledAct){
            Helper.fetchActivityById(this.props.selectedActivityId).scheduledAct.forEach((oActivity)=>{
                arNew.push({actId:oActivity.actId,dt:new Date(oActivity.dt.toDate ? oActivity.dt : oActivity.dt),arReservations:oActivity.arReservations ? oActivity.arReservations.map((oRsv)=>{
                    return {id:oRsv.id,num:oRsv.num,orgNum:oRsv.num};
                }) : []});
            });
            this.setState({
                arScheduledAct:arNew,
                date:new Date(),
            })
        }
        */
    }
    openSelectedDateInCalendar(){
        if (!!Helper.fetchActivityById(this.props.selectedActivityId) && !!this.props.selectedScheduledActivityId && Helper.fetchActivityById(this.props.selectedActivityId).oScheduledAct[this.props.selectedScheduledActivityId]){
            var oDt=Helper.fetchActivityById(this.props.selectedActivityId).oScheduledAct[this.props.selectedScheduledActivityId].dt;
            this.props.setSelectedScheduledActivityId(null);
            this.calendarRef.current.setActiveStartDate(oDt);
            window.setTimeout(function(){
                document.querySelectorAll(".markTile").forEach((elm)=>{
                    console.log (elm.innerText);
                    if (elm.innerText==oDt.getDate())elm.click();
                });
            },100)
        }
    }
    componentDidUpdate(){
        const _self=this;
        if (!!Helper.fetchActivityById(this.props.selectedActivityId) && this.state.bIsContMounting){
            this.componentDidMount_cont();
            this.setState({
                bIsContMounting:false,
            });
            return;
        }
        this.resetHoursJournal();
        for (let sProp in this.state.oScheduledAct){
            if ((this.state.oScheduledAct[sProp].dt.getFullYear()===this.state.date.getFullYear() && 
                this.state.oScheduledAct[sProp].dt.getMonth()===this.state.date.getMonth() && 
                this.state.oScheduledAct[sProp].dt.getDate()===this.state.date.getDate())
                ||
                (this.state.oScheduledAct[sProp].dt.getTime()>(new Date()).getTime() && 
                this.state.oScheduledAct[sProp][this.props.userId] && 
                parseInt(this.state.oScheduledAct[sProp][this.props.userId].num,10)>0 &&
                parseInt(this.state.oScheduledAct[sProp][this.props.userId].rsvStatus,10)>0)){
                if (this.props.type!=="choose"){
                    this.hoursJournalRef.current.querySelector("#eInterestingActivities").classList.add("hideMe");
                    document.getElementById("eHoursJournal").querySelectorAll(".entryWrapper:not(#eEntryWrapperPrototype)").forEach((elm)=>{
                        if (parseInt(elm.querySelector(".hours").getAttribute("data-start"),10)===this.state.oScheduledAct[sProp].dt.getHours()){
                            for (let sUserId in this.state.oScheduledAct[sProp]){
                                if (sUserId!=="dt" && parseInt(elm.querySelector(".hours").getAttribute("data-start"),10)===this.state.oScheduledAct[sProp].dt.getHours()){
                                    let oRegisteredWrapper=document.createElement("span");
                                    let oRegisteredUser=document.createElement("span");
                                    oRegisteredWrapper.classList.add("registeredWrapper");
                                    oRegisteredWrapper.setAttribute("data-schedActId",sProp);
                                    oRegisteredWrapper.setAttribute("data-num",this.state.oScheduledAct[sProp][sUserId].num);
                                    oRegisteredUser.classList.add("registeredUser");
                                    oRegisteredUser.setAttribute("data-userid",sUserId);
                                    oRegisteredUser.innerHTML=!!Helper.fetchRunnerById(sUserId) && Helper.fetchRunnerById(sUserId).name ? Helper.fetchRunnerById(sUserId).name : "unspecified name";
                                    oRegisteredUser.addEventListener("click",(e)=>{
                                        this.props.setSelectedRunnerId(e.target.getAttribute("data-userid"));
                                        this.props.setPage(RUNNER_PAGE);
                                    });
                                    if (this.state.oScheduledAct[sProp][sUserId].rsvStatus>0 && this.state.oScheduledAct[sProp][sUserId].num>0){
                                        if (this.state.oScheduledAct[sProp][sUserId].rsvStatus===1){
                                            oRegisteredWrapper.innerHTML=this.state.oScheduledAct[sProp][sUserId].num + " "+(parseInt(this.state.oScheduledAct[sProp][sUserId].num ,10)===1 ? "spot reservation request" : "spots reservation request");
                                        }else{
                                            oRegisteredWrapper.innerHTML=this.state.oScheduledAct[sProp][sUserId].num + " "+(parseInt(this.state.oScheduledAct[sProp][sUserId].num ,10)===1 ? "spot reserved" : "spots reserved");
                                        }
                                        oRegisteredWrapper.innerHTML+=" by ";
                                        oRegisteredWrapper.appendChild(oRegisteredUser);
                                        if (this.state.oScheduledAct[sProp][sUserId].rsvStatus===1){
                                            let oAcceptBtn=document.createElement("button");
                                            let oRejectBtn=document.createElement("button");
                                            oAcceptBtn.innerHTML="accept";
                                            oRejectBtn.innerHTML="reject";
                                            oAcceptBtn.classList.add("acceptRegRequestBtn");
                                            oRejectBtn.classList.add("rejectRegRequetBtn");
                                            if (this.state.oScheduledAct[sProp].dt.getTime()>(new Date()).getTime()){
                                                const _self=this;
                                                oAcceptBtn.addEventListener("click",function(e){
                                                    const sUserId=e.target.parentNode.querySelector(".registeredUser").getAttribute("data-userid");
                                                    const sSchedActId=e.target.parentNode.getAttribute("data-schedActId");
                                                    const iNum=parseInt(e.target.parentNode.getAttribute("data-num"),10);
                                                    _self.save(sSchedActId,sUserId,iNum,2);
                                                });
                                                oRejectBtn.addEventListener("click",function(e){
                                                    const sUserId=e.target.parentNode.querySelector(".registeredUser").getAttribute("data-userid");
                                                    const sSchedActId=e.target.parentNode.getAttribute("data-schedActId");
                                                    const iNum=parseInt(e.target.parentNode.getAttribute("data-num"),10);
                                                    _self.save(sSchedActId,sUserId,iNum,0);
                                                });
                                            }
                                            else{
                                                oAcceptBtn.setAttribute("disabled","true");                                                
                                                oRejectBtn.setAttribute("disabled","true");                                                
                                            }
                                            oRegisteredWrapper.appendChild(oAcceptBtn);
                                            oRegisteredWrapper.appendChild(oRejectBtn);
                                        }
                                        elm.querySelector(".numOfRsv").appendChild(oRegisteredWrapper);
                                    }
                                }
                            }
                            elm.classList.remove("hideMe");
                            this.showAddedActivity(elm);
                            return false;
                        }
                    });
                }
                else{
                    let eScheduledAct=this.reserveWrapperPrototypeRef.current.cloneNode(true),iReservations=0,iReservationsRequests=0;
                    eScheduledAct.setAttribute("id","eScheduledAct_"+sProp);
                    eScheduledAct.setAttribute("data-id",sProp);
                    eScheduledAct.classList.remove("hideMe");
                    eScheduledAct.classList.add("scheduledAct");
                    const iEndTime=this.state.oScheduledAct[sProp].dt.getHours()+this.state.activityDuration>=24 ? this.state.oScheduledAct[sProp].dt.getHours()+this.state.activityDuration-24 : this.state.oScheduledAct[sProp].dt.getHours()+this.state.activityDuration;
                    
                    const sDt=Helper.getWeekDayName(this.state.oScheduledAct[sProp].dt)+", "+Helper.getMonthShortName(this.state.oScheduledAct[sProp].dt)+" "+this.state.oScheduledAct[sProp].dt.getDate();
                    if (this.state.oScheduledAct[sProp][this.props.userId] && parseInt(this.state.oScheduledAct[sProp][this.props.userId].num,10)>0){/*if it's in the reserved section then we need a date, otherwise we need a caption for the activities by dt section*/
                        eScheduledAct.querySelector(".rsvDate").innerHTML=sDt;
                    }
                    else{
                        this.hoursJournalRef.current.querySelector("#eActivitiesToDt>.caption").innerHTML=sDt;
                    }
                    eScheduledAct.querySelector(".rsvTime").innerHTML=this.state.oScheduledAct[sProp].dt.getHours()+":00"+" - "+iEndTime+":00";
                    let iTotalRsv=0;
                    
                    for (let sRsvId in this.state.oScheduledAct[sProp]){
                        if (typeof this.state.oScheduledAct[sProp][sRsvId] === "object" && this.state.oScheduledAct[sProp][sRsvId].id){
                            let oRsv=this.state.oScheduledAct[sProp][sRsvId];
                            if (oRsv.rsvStatus===2){
                                if (oRsv.id===this.props.userId){
                                    iReservations=parseInt(oRsv.num,10);
                                }
                                iTotalRsv+=parseInt(oRsv.num,10);
                            }
                            else if (oRsv.rsvStatus===1 && oRsv.id===this.props.userId){
                                iReservationsRequests+=parseInt(oRsv.num,10);
                            }
                        }
                    }
                    for (let ii=1;ii<=Helper.fetchActivityById(this.props.selectedActivityId).maxNumOfParticipants-(iTotalRsv-iReservations);ii++){
                        let eOption=document.createElement("option");
                        eOption.value=ii;
                        eOption.text=ii;
                        eScheduledAct.querySelector(".rsvReserve").appendChild(eOption);
                    }
                    if (iReservations>0 || iReservationsRequests>0){
                        if (iReservations>0){
                            eScheduledAct.querySelector(".rsvByYou>.numOfSpotsReserved").innerHTML=iReservations;
                            if (iReservations>1)eScheduledAct.querySelector(".rsvByYou>.oneOrMany").innerHTML="spots";
                            eScheduledAct.querySelector(".joinOrJoining").innerHTML="you are joining";
                            eScheduledAct.querySelector(".rsvByYou").classList.remove("hideMe");
                            eScheduledAct.querySelector(".rsvReserve").value=iReservations;
    
                        }
                        if (iReservationsRequests>0){
                            eScheduledAct.querySelector(".rsvRequestsByYou>.numOfSpotsReserved").innerHTML=iReservationsRequests;
                            if (iReservationsRequests>1)eScheduledAct.querySelector(".rsvRequestsByYou>.oneOrMany").innerHTML="spots";
                            eScheduledAct.querySelector(".joinOrJoining").innerHTML="you might be joining";
                            eScheduledAct.querySelector(".rsvRequestsByYou").classList.remove("hideMe");
                        }
                        eScheduledAct.querySelector(".bUpdate").classList.remove("hideMe");
                        eScheduledAct.querySelector(".bUpdate").addEventListener("click",e=>this.reserveActivity(e));
                    }
                    else{
                        eScheduledAct.querySelector(".bAdd").classList.remove("hideMe");
                        eScheduledAct.querySelector(".bAdd").addEventListener("click",e=>this.reserveActivity(e));
                    }
                    if (this.state.oScheduledAct[sProp].dt.getTime()<=(new Date()).getTime() || 
                        Helper.fetchActivityById(this.props.selectedActivityId).initiator===this.props.userId){
                            eScheduledAct.querySelector(".unavailableActivityCaption").classList.remove("hideMe");
                            eScheduledAct.querySelector(".bAdd").classList.add("hideMe");
                            eScheduledAct.querySelector(".bUpdate").classList.add("hideMe");
                    }

                    if (iTotalRsv-iReservations>0){
                        eScheduledAct.querySelector(".rsvByOthers>.numOfSpotsReserved").innerHTML=iTotalRsv-iReservations;
                        if (iTotalRsv-iReservations>1)eScheduledAct.querySelector(".rsvByOthers>.oneOrMany").innerHTML="participants";
                        eScheduledAct.querySelector(".rsvByOthers").classList.remove("hideMe");
                    }
                    if (this.state.oScheduledAct[sProp][this.props.userId] && parseInt(this.state.oScheduledAct[sProp][this.props.userId].num,10)>0){
                        this.hoursJournalRef.current.querySelector("#eInterestingActivities").appendChild(eScheduledAct);
                        this.hoursJournalRef.current.querySelector("#eInterestingActivities>.caption").innerHTML="reserved activities:";
                    }else{
                        this.hoursJournalRef.current.querySelector("#eActivitiesToDt").appendChild(eScheduledAct);
                    }                    
                    eScheduledAct.querySelector(".bAdd").removeAttribute("disabled");
                    eScheduledAct.querySelector(".bUpdate").removeAttribute("disabled");
                }
            }
        }
        this.refreshMarkedTilesInCalendar();
        this.openSelectedDateInCalendar();
    }
    componentDidUpdate_old(){
        var jj,kk;
        this.resetHoursJournal();
        for (jj=0;jj<this.state.arScheduledAct.length;jj++){
            if (this.state.arScheduledAct[jj].dt.getFullYear()===this.state.date.getFullYear() && 
                this.state.arScheduledAct[jj].dt.getMonth()===this.state.date.getMonth() && 
                this.state.arScheduledAct[jj].dt.getDate()===this.state.date.getDate()){
                if (this.props.type!=="choose"){
                    document.getElementById("eHoursJournal").querySelectorAll(".entryWrapper:not(#eEntryWrapperPrototype)").forEach((elm)=>{
                        if (parseInt(elm.querySelector(".hours").getAttribute("data-start"),10)===this.state.arScheduledAct[jj].dt.getHours()){
                            elm.classList.remove("hideMe");
                            this.showAddedActivity(elm);
                            return false;
                        }
                    });
                }
                else{
                    let eScheduledAct=this.reserveWrapperPrototypeRef.current.cloneNode(true),iReservations=parseInt(Helper.fetchActivityById(this.props.selectedActivityId).maxNumOfParticipants,10);
                    let iOrgReservations=0;
                    let bIsReservationMade=false;
                    eScheduledAct.setAttribute("id","eScheduledAct"+jj);
                    eScheduledAct.setAttribute("data-indexInList",jj);
                    eScheduledAct.classList.remove("hideMe");
                    eScheduledAct.classList.add("scheduledAct");
                    const iEndTime=this.state.arScheduledAct[jj].dt.getHours()+this.state.activityDuration>=24 ? this.state.arScheduledAct[jj].dt.getHours()+this.state.activityDuration-24 : this.state.arScheduledAct[jj].dt.getHours()+this.state.activityDuration;
                    eScheduledAct.querySelector(".rsvTime").innerHTML=this.state.arScheduledAct[jj].dt.getHours()+" - "+iEndTime;
                    eScheduledAct.querySelector(".rsvTotalSpots").innerHTML=Helper.fetchActivityById(this.props.selectedActivityId).maxNumOfParticipants;


                    let iTotalRsv=0;
                    this.state.arScheduledAct[jj].arReservations && this.state.arScheduledAct[jj].arReservations.forEach((oRsv)=>{
                        if (oRsv.id===this.props.userId){
                            iReservations=parseInt(oRsv.num,10);
                            iOrgReservations=parseInt(oRsv.orgNum,10);
                            if (iOrgReservations>0)bIsReservationMade=true;
                        }
                        iTotalRsv+=parseInt(oRsv.num,10);
                    })

                    for (let ii=1;ii<=Helper.fetchActivityById(this.props.selectedActivityId).maxNumOfParticipants-(iTotalRsv-iOrgReservations);ii++){
                        let eOption=document.createElement("option");
                        eOption.value=ii;
                        eOption.text=ii;
                        eScheduledAct.querySelector(".rsvReserve").appendChild(eOption);
                    }

                    eScheduledAct.querySelector(".rsvAvailableSpots").innerHTML=Helper.fetchActivityById(this.props.selectedActivityId).maxNumOfParticipants-iTotalRsv;

                    eScheduledAct.querySelector(".rsvReserve").value=bIsReservationMade ? iReservations : 0;

                    eScheduledAct.querySelector(".numOfSpotsReserved").innerHTML=iOrgReservations;

                    eScheduledAct.querySelector(".bAdd").addEventListener("click",e=>this.reserveActivity(e));
                    eScheduledAct.querySelector(".bUpdate").addEventListener("click",e=>this.reserveActivity(e));
                    eScheduledAct.querySelector(".rsvReserve").addEventListener("change",e=>this.chooseNumOfParticipants(e));

                    this.hoursJournalRef.current.appendChild(eScheduledAct);

                    if (bIsReservationMade){
                        eScheduledAct.querySelector(".bAdd").classList.add("hideMe");
                        eScheduledAct.querySelector(".bUpdate").classList.remove("hideMe");
                        eScheduledAct.querySelector(".rsvReserve option:nth-child(1)").innerHTML="0";
                    }
                    if (iReservations!==iOrgReservations){
                        eScheduledAct.querySelector(".bAdd").removeAttribute("disabled");
                        eScheduledAct.querySelector(".bUpdate").removeAttribute("disabled");
                    }else{
                        eScheduledAct.querySelector(".bAdd").setAttribute("disabled","disabled");
                        eScheduledAct.querySelector(".bUpdate").setAttribute("disabled","disabled");
                    }
                }
            }
        }
        this.refreshMarkedTilesInCalendar();
    }
    reserveActivity(e){
        if (parseInt(e.target.parentNode.parentNode.querySelector(".rsvReserve").value,10)>-1){
            this.save(e.target.parentNode.parentNode.getAttribute("data-id"),this.props.userId,parseInt(e.target.parentNode.parentNode.querySelector(".rsvReserve").value,10),1);
        }
        else{
            alert ("you have to choose num of participants");
        }
//        const iIndexInList=e.target.parentNode.parentNode.getAttribute("data-indexInList");
//        alert ("in reserve. index in list is -"+iIndexInList+ "*** user id="+this.props.userId+" *** num of spots reserved="+e.target.parentNode.parentNode.querySelector(".rsvReserve").value);
/*
    if (!this.arScheduledAct[iIndexInList].arReservations)this.arScheduledAct[iIndexInList].arReservations=[];
        this.setState((prevState,prevProps)=>({
            arScheduledAct:prevState.arScheduledAct.push()
        }));
        this.arScheduledAct[iIndexInList].arReservations.push({userId:this.props.userId,numOfRsv:e.target.parentNode.parentNode.querySelector(".rsvReserve").value});
        this.save();
*/        
    }
    chooseNumOfParticipants_old(e){
        const iIndexInList=e.target.parentNode.parentNode.getAttribute("data-indexInList");
        let oScheduledAct=this.state.arScheduledAct[iIndexInList],bIsFound=false;
        if (!oScheduledAct.arReservations)oScheduledAct.arReservations=[];
        oScheduledAct.arReservations.forEach((oRsv)=>{
            if (oRsv.id===this.props.userId){
                oRsv.num=e.target.value;
                bIsFound=true;
            }
        })
        if (!bIsFound){
            oScheduledAct.arReservations.push({id:this.props.userId,num:e.target.value,orgNum:0});
        }

        this.setState();
        
//        alert ("in chooseNumOfParticipants. index in list is -"+iIndexInList+ "*** user id="+this.props.userId+" *** num of spots reserved="+e.target.value);
    }
    sendMsg (sMsg,sRunnerId,sActivityId,sScheduledActivityId,sMsgType){
        db.collection("runners").doc(sRunnerId).update({
            messagesReceived:firebase.firestore.FieldValue.arrayUnion({senderId:firebase.auth().currentUser.uid,msg:sMsg,activityId:sActivityId,scheduledActId:sScheduledActivityId,msgType:sMsgType}),
            isShowNewMsgsAlert:true,
        })
        .then(()=>{
            Helper.sendEmail(this.props.me.name,sMsg,Helper.fetchRunnerById(sRunnerId).email);
        })
        .catch((error)=>{
            console.error("an error in saving IM to db in activitiesScheduler. error is - ",error);
        })
    }
}

const mapStateToProps = (state)=>{
    return {
        selectedActivityId:state.upState.selectedActivityId,
        selectedScheduledActivityId:state.upState.selectedScheduledActivityId,
        prevPg:state.upState.prevPg,
        userId:state.upState.user.id,
        me:state.upState.oUser,
        fetchActivityState:state.activities.action,
        fetchRunnersState:state.runners.action,
    }
}
const mapDispatchToProps = (dispatch)=>{
    return {
        setPage:(sPg,bSetPrev)=>{dispatch(setPage(sPg,bSetPrev))},
        replaceActivity:oActivity=>{dispatch(replaceActivity(oActivity))},
        setSelectedRunnerId:(sRunnerId)=>{dispatch(setSelectedRunnerId(sRunnerId))},
        setSelectedScheduledActivityId:(sId)=>{dispatch(setSelectedScheduledActivityId(sId))},
        fetchOneRunner:(sRunnerId)=>{dispatch(fetchOneRunner(sRunnerId))},
        fetchOneActivity:(sActivityId)=>{dispatch(fetchOneActivity(sActivityId))},
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ActivitiesScheduler);