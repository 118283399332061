import React,{Component} from "react";
import {connect} from "react-redux";
import {setPage,connectUser} from "../redux/actions/state";
import {setChatterId,setChatText} from "../redux/actions/chat";
import firebase,{db} from "./firebase";
import {startTracking} from "../helper";

class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            password:localStorage.getItem("rmbFlds")==="1" ? localStorage.getItem("pwd") : "",
            email:localStorage.getItem("rmbFlds")==="1" ? localStorage.getItem("email") : "",
            bRememberFlds:localStorage.getItem("rmbFlds")==="1",
        };
        this.login=this.login.bind(this);
        this.setPassword=this.setPassword.bind(this);
        this.setEmail=this.setEmail.bind(this);
        this.closePopup=this.closePopup.bind(this);
        this.chngRememberFldsState=this.chngRememberFldsState.bind(this);
    }
    render(){
        return (
            <div id="eLoginPg">
                <div id="eLoginPopup">
                    <div id="eClosePopupBtn" onClick={this.closePopup}></div>
                    <input id="eEmail" className="itm inputFld" type="text" placeholder="email" autoFocus value={this.state.email} onChange={this.setEmail}/>
                    <input id="ePassword" className="itm inputFld" type="password" placeholder="password" value={this.state.password} onChange={this.setPassword}/>
                    <button id="eLoginBtn" className="itm btn" onKeyPress={(e)=>{if (e.keyCode===13)this.login();}} onClick={this.login}>Log in</button>
                    <div id="eRememberFldsWrapper" className="itm"><input type="checkbox" checked={this.state.bRememberFlds ? true : false} onChange={this.chngRememberFldsState}/> remember details</div>
                    </div>
            </div>
        )
    }
    componentDidMount(){
        startTracking(this);
    }
    validate(){
        var bValid=true;
        const sPwd=this.state.password,sEmail=this.state.email;
        if (sPwd.trim()===""){
            alert ("the password fld is empty");
            document.querySelector("#eLoginPg #ePassword").focus();
            bValid=false;
        }
        else if (sEmail.trim()===""){
            alert ("the email fld is empty");
            document.querySelector("#eLoginPg #eEmail").focus();
            bValid=false;
        }
        return bValid;
    }
    login(){
        const sEmail=this.state.email.trim(),sPwd=this.state.password.trim(),self=this;
        if (!this.validate()) return;
        firebase.auth().signInWithEmailAndPassword(sEmail/*+"12345678@gmail.com"*/,sPwd)
        .then(function(user){
            localStorage.setItem("rmbFlds",self.state.bRememberFlds ? "1" : "0");
            localStorage.setItem("email",self.state.bRememberFlds ? sEmail : "");
            localStorage.setItem("pwd",self.state.bRememberFlds ? sPwd : "");
        })
        .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            alert (errorMessage);
            // ...
        });        
    }
    setEmail(e){
        this.setState({
            email:e.target.value,
        })
    }
    setPassword(e){
        this.setState({
            password:e.target.value,
        })
    }
    closePopup(){
        this.props.setPage(this.props.prevPg);
    }
    chngRememberFldsState(e){
        this.setState({
            bRememberFlds:e.target.checked,
        })
    }
}
const mapStateToProps = (state)=>{
    return {
        prevPg:state.upState.prevPg,
    }
}
const mapDispatchToProps = (dispatch)=>{
    return {
        setPage:(sPg)=>{dispatch(setPage(sPg))},
        setChatterId:(sChatterId)=>{dispatch(setChatterId(sChatterId))},
        setChatText:(sSide,sChatText)=>{dispatch(setChatText(sSide,sChatText))},
        connectUser:(sUserNm,oUser)=>{dispatch(connectUser(sUserNm,oUser))},
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);