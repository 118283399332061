import { createStore, combineReducers,applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import itemsReducer from "../reducers/items";
import citiesReducer from "../reducers/citiesReducer";
import languagesReducer from "../reducers/languagesReducer";
import stateReducer from "../reducers/stateReducer";
import runnersReducer from "../reducers/runnersReducer";
import activitiesReducer from "../reducers/activitiesReducer";
import activityScopeReducer from "../reducers/activityScopeReducer";
import activityTypeReducer from "../reducers/activityTypeReducer";
import availabilityReducer from "../reducers/availabilityReducer";
import languageMasteryReducer from "../reducers/languageMasteryReducer";
import locationReducer from "../reducers/locationReducer";
import chatReducer from "../reducers/chatReducer";

 function configureStore(initialState) {
    return createStore(
        combineReducers({
            items: itemsReducer,
            cities:citiesReducer,
            languages:languagesReducer,
            availability:availabilityReducer,
            activityScope:activityScopeReducer,
            activityType:activityTypeReducer,
            languageMastery:languageMasteryReducer,
            location:locationReducer,
            upState:stateReducer,
            runners:runnersReducer,
            activities:activitiesReducer,
            chat:chatReducer,
            /* add here as much reducers as you like */
          }),
          initialState,
        applyMiddleware(thunk)
    );
}

const store = configureStore();
export default store;
