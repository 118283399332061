import React,{Component} from "react";
import {connect} from "react-redux";
import {NONE,FETCH_ACTIVITIES_BEGIN,FETCH_ACTIVITIES_SUCCESS,FETCH_ACTIVITIES_ERROR} from "../constants.js";
import { fetchActivities } from "../redux/actions/activities";
import ActivityCard from "./ActivityCard";
import firebase from "./firebase";

class Activities extends Component {
    constructor(props){
        super(props);
    }
    render(){
        switch (this.props.action){
            case NONE:
            case FETCH_ACTIVITIES_BEGIN:
                return (
                    <div></div>
                )
            case FETCH_ACTIVITIES_SUCCESS:
                let arActivities=[],bActivitiesFound=false;
                if (this.props.activities){
                    arActivities=this.props.activities.map((oActivity,iIndx)=>{
                        if (oActivity.city===this.props.cityId){ 
                            bActivitiesFound=true;
                            return (
                                <div key={iIndx}>
                                    <ActivityCard activityId={oActivity.id}/>
                                </div>
                            );
                        };
                    });
                }
                return (
                    <div className="activitiesWrapper">
                        {bActivitiesFound ? arActivities : <div id="eNoActivitiesMsg"><span>There are currently no scheduled activities in this city</span></div>}
                    </div>
                )
            case FETCH_ACTIVITIES_ERROR:
                return (
                    <div>some error occurred in activities pg</div>
                )
            default:
                return (
                    <div>looking for mr godbar in activities pg</div>
                )
        }
    }
    componentDidMount(){
        this.props.fetchActivities(this.props.cityId);
    }
}

const mapStateToProps = (state)=>{
    return {
        action:state.activities.action,
        activities:state.activities.activities,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        fetchActivities:(cityId)=>dispatch(fetchActivities(cityId)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Activities);

