import {NONE,FETCH_ACTIVITIES_BEGIN,FETCH_ACTIVITIES_SUCCESS,REPLACE_ACTIVITY,ADD_ACTIVITY,FETCH_ONE_ACTIVITY_SUCCESS} from "../../constants.js";
const initialState = {
    action:NONE,
    activities:[],
}

const activitiesReducer = (state=initialState,{type,activities,activity})=>{
    let arActivities=null;
    switch (type){
        case FETCH_ACTIVITIES_BEGIN:
            return {
                ...state,
                action:type,
            }
        case FETCH_ACTIVITIES_SUCCESS:
            arActivities=state.activities.slice();
            activities.forEach((activity)=>{
                if (arActivities.filter((oActivity)=>oActivity.id===activity.id).length===0)arActivities.push(activity);
            });
            return {
                ...state,
                action:type,
                activities:arActivities,
            }
        case FETCH_ONE_ACTIVITY_SUCCESS:
            arActivities=state.activities.slice();
            if (arActivities.filter((oActivity)=>oActivity.id===activity.id).length===0)arActivities.push(activity);
            return {
                ...state,
                action:type,
                activities:arActivities,
            }
        case REPLACE_ACTIVITY:
            arActivities=state.activities.slice();
            arActivities.forEach((oActivity,iIndex)=>{
                if (oActivity.id===activity.id){
                    arActivities.splice(iIndex,1,activity);
                    return false;
                };
            });
            return{
                ...state,
                action:type,
                activities:arActivities,
            };
        case ADD_ACTIVITY:
            arActivities=state.activities.slice();
            arActivities.push(activity);
            return{
                ...state,
                action:type,
                activities:arActivities,
            };
    default:
            return state;
    }
}

export default activitiesReducer;