import {NONE,FETCH_LANGUAGEMASTERY_SUCCESS,FETCH_LANGUAGEMASTERY_ERROR} from "../../constants.js";
import {db} from "../../components/firebase";

export const fetchLanguageMasterySuccess = (languageMastery)=>({
    type:FETCH_LANGUAGEMASTERY_SUCCESS,
    languageMastery,
});

export const fetchLanguageMasteryFailure = (error)=>({
    type:FETCH_LANGUAGEMASTERY_ERROR,
    error,
});

export const fetchLanguageMastery = ()=>{
    return (dispatch) => {
        db.collection("languageMastery")
        .orderBy("order","desc")
        .get()
        .then(function(querySnapshot) {
            var arLanguageMastery=[];
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                arLanguageMastery.push({id:doc.id,...doc.data()});
            });
            dispatch(fetchLanguageMasterySuccess(arLanguageMastery));
        })
        .catch(function(error) {
            dispatch(fetchLanguageMasteryFailure(error));
        });
    }
}