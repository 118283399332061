export const FETCH_CITY_BEGIN="FETCH_CITY_BEGIN";
export const FETCH_CITY_SUCCESS="FETCH_CITY_SUCCESS";
export const FETCH_CITY_ERROR="FETCH_CITY_ERROR";
export const FETCH_RUNNERS_BEGIN="FETCH_RUNNERS_BEGIN";
export const FETCH_RUNNERS_SUCCESS="FETCH_RUNNERS_SUCCESS";
export const FETCH_RUNNERS_ERROR="FETCH_RUNNERS_ERROR";
export const REPLACE_RUNNER="REPLACE_RUNNER";
export const FETCH_ACTIVITIES_BEGIN="FETCH_ACTIVITIES_BEGIN";
export const FETCH_ACTIVITIES_SUCCESS="FETCH_ACTIVITIES_SUCCESS";
export const REPLACE_ACTIVITY="REPLACE_ACTIVITY";
export const ADD_ACTIVITY="ADD_ACTIVITY";
export const FETCH_ACTIVITIES_ERROR="FETCH_ACTIVITIES_ERROR";
export const FETCH_ONE_RUNNER_BEGIN="FETCH_ONE_RUNNER_BEGIN";
export const FETCH_ONE_RUNNER_ERROR="FETCH_ONE_RUNNER_ERROR";
export const FETCH_ONE_RUNNER_SUCCESS="FETCH_ONE_RUNNER_SUCCESS";
export const FETCH_LANGUAGES_SUCCESS="FETCH_LANGUAGES_SUCCESS";
export const FETCH_LANGUAGES_ERROR="FETCH_LANGUAGES_ERROR";
export const FETCH_AVAILABILITY_SUCCESS="FETCH_AVAILABILITY_SUCCESS";
export const FETCH_AVAILABILITY_ERROR="FETCH_AVAILABILITY_ERROR";
export const FETCH_LANGUAGEMASTERY_SUCCESS="FETCH_LANGUAGEMASTERY_SUCCESS";
export const FETCH_LANGUAGEMASTERY_ERROR="FETCH_LANGUAGEMASTERY_ERROR";
export const FETCH_LOCATION_SUCCESS="FETCH_LOCATION_SUCCESS";
export const FETCH_LOCATION_ERROR="FETCH_LOCATION_ERROR";
export const FETCH_ACTIVITYSCOPE_SUCCESS="FETCH_ACTIVITYSCOPE_SUCCESS";
export const FETCH_ACTIVITYSCOPE_ERROR="FETCH_ACTIVITYSCOPE_ERROR";
export const FETCH_ACTIVITYTYPE_SUCCESS="FETCH_ACTIVITYTYPE_SUCCESS";
export const FETCH_ACTIVITYTYPE_ERROR="FETCH_ACTIVITYTYPE_ERROR";
export const FETCH_ONE_ACTIVITY_BEGIN="FETCH_ONE_ACTIVITY_BEGIN";
export const FETCH_ONE_ACTIVITY_SUCCESS="FETCH_ONE_ACTIVITY_SUCCESS";
export const FETCH_ONE_ACTIVITY_ERROR="FETCH_ONE_ACTIVITY_ERROR";
export const NONE="NONE";
export const SET_PG="SET_PG";
export const ACTIVITY_MAKER_PAGE="ACTIVITY_MAKER_PAGE";
export const ACTIVITY_PAGE="ACTIVITY_PAGE";
export const APPETIZER_PAGE="APPETIZER_PAGE";
export const CITY_PAGE="CITY_PAGE";
export const RUNNER_PAGE="RUNNER_PAGE";
export const LOGIN_PAGE="LOGIN_PAGE";
export const SIGNUP_PAGE="SIGNUP_PAGE";
export const PROFILE_PAGE="PROFILE_PAGE";
export const CHAT_PAGE="CHAT_PAGE";
export const SET_DATES_PAGE="SET_DATES_PAGE";
export const CHOOSE_DATES_PAGE="CHOOSE_DATES_PAGE";
export const SET_CITY_ID="SET_CITY_ID";
export const SET_RUNNER_ID="SET_RUNNER_ID";
export const SET_ACTIVITY_ID="SET_ACTIVITY_ID";
export const SET_SCHEDULED_ACTIVITY_ID="SET_SCHEDULED_ACTIVITY_ID";
export const CONNECT_USER="CONNECT_USER";
export const DISCONNECT_USER="DISCONNECT_USER";
export const REPLACE_USER="REPLACE_USER";
export const ADD_CONNECTED="ADD_CONNECTED";
export const REMOVE_CONNECTED="REMOVE_CONNECTED";
export const SET_CHATTER_ID="SET_CHATTER_ID";
export const SET_CHAT_TEXT="SET_CHAT_TEXT";
export const CLEAR_CHAT="CLEAR_CHAT";
export const SET_NUM_OF_RUNNERS_TO_RETRIEVE="SET_NUM_OF_RUNNERS_TO_RETRIEVE";
export const DECREASE_ONE_FROM_NUM_OF_RUNNERS_TO_RETRIEVE="DECREASE_ONE_FROM_NUM_OF_RUNNERS_TO_RETRIEVE";
export const SET_USER_NAME="SET_USER_NAME";
export const ABOUT_PAGE="ABOUT_PAGE";