import {SET_CHAT_TEXT,SET_CHATTER_ID,CLEAR_CHAT} from "../../constants.js";


const initialState={
    chat:[],
    chatterId:null,
}

const chatReducer = (state=initialState,{type,side,chatText,chatterId})=>{
    switch (type){
        case SET_CHAT_TEXT:
            let arChatNew=state.chat.slice();
            arChatNew.push({side,chatText});
            return {
                ...state,
                chat:arChatNew,
            }
        case SET_CHATTER_ID:
            return {
                ...state,
                chatterId,
            }
        case CLEAR_CHAT:
            return{
                ...state,
                chat:[],
            }
        default:
            return state;
    }
}

export default chatReducer;