
import {NONE,FETCH_ACTIVITYTYPE_SUCCESS,FETCH_ACTIVITYTYPE_ERROR} from "../../constants.js";
const initialState = {
    action:NONE,
    activityType:[],
}


const activityTypeReducer = (state=initialState,{type,activityType})=>{
    switch (type){
        case FETCH_ACTIVITYTYPE_SUCCESS:
            return {
                ...state,
                action:type,
                activityType,
            }
        case FETCH_ACTIVITYTYPE_ERROR:
            return {
                ...state,
                action:type,
            }
        default:
            return state;
    }
}

export default activityTypeReducer;