import {NONE,FETCH_AVAILABILITY_SUCCESS,FETCH_AVAILABILITY_ERROR} from "../../constants.js";
import {db} from "../../components/firebase";

export const fetchAvailabilitySuccess = (availability)=>({
    type:FETCH_AVAILABILITY_SUCCESS,
    availability,
});

export const fetchAvailabilityFailure = (error)=>({
    type:FETCH_AVAILABILITY_ERROR,
    error,
});

export const fetchAvailability = ()=>{
    return (dispatch) => {
        db.collection("availability")
        .orderBy("name")
        .get()
        .then(function(querySnapshot) {
            var arAvailability=[];
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                arAvailability.push({id:doc.id,...doc.data()});
            });
            dispatch(fetchAvailabilitySuccess(arAvailability));
        })
        .catch(function(error) {
            dispatch(fetchAvailabilityFailure(error));
        });
    }
}