import {NONE,FETCH_LOCATION_SUCCESS,FETCH_LOCATION_ERROR} from "../../constants.js";
import {db} from "../../components/firebase";

export const fetchLocationSuccess = (location)=>({
    type:FETCH_LOCATION_SUCCESS,
    location,
});

export const fetchLocationFailure = (error)=>({
    type:FETCH_LOCATION_ERROR,
    error,
});

export const fetchLocation = ()=>{
    return (dispatch) => {
        db.collection("location")
        .orderBy("name")
        .get()
        .then(function(querySnapshot) {
            var arLocation=[];
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                arLocation.push({id:doc.id,...doc.data()});
            });
            dispatch(fetchLocationSuccess(arLocation));
        })
        .catch(function(error) {
            dispatch(fetchLocationFailure(error));
        });
    }
}