import React,{Component} from "react";
import {connect} from "react-redux";
import {setPage} from "../redux/actions/state";
import {replaceUser} from "../redux/actions/state";
import {replaceRunner} from "../redux/actions/runners";
import firebase,{db} from "./firebase";
import Helper from "../helper";
import VideoRecorder from "react-video-recorder";

class ProfileMaker extends Component {
    constructor(props){
        super(props);
        let oUser={};
        oUser["imgName"]="";
        oUser["10 km avg"]="";
        oUser["20 km avg"]="";
        oUser["about myself"]="";
        oUser["cityId"]="-1";
        oUser["gender"]="M";
        oUser["languages"]="";
        oUser["name"]="";
        oUser["languages"]=[];

        this.state={
            user:oUser,
            mainImg:"",
            mainVidBlob:null,
            isInitialVidOn:false,
            initialVidSrc:"",
        };

        this.uploadVidBtn=React.createRef();
        this.setRecMode=this.setRecMode.bind(this);
        this.set10KmAvgValue=this.set10KmAvgValue.bind(this);
        this.set20KmAvgValue=this.set20KmAvgValue.bind(this);
        this.setAboutMyselfValue=this.setAboutMyselfValue.bind(this);
        this.setCityValue=this.setCityValue.bind(this);
        this.setGenderValue=this.setGenderValue.bind(this);
        this.setNameValue=this.setNameValue.bind(this);
        this.uploadImg=this.uploadImg.bind(this);
        this.uploadVid=this.uploadVid.bind(this);
        this.downloadImg=this.downloadImg.bind(this);
        this.addLanguage=this.addLanguage.bind(this);
        this.removeLanguage=this.removeLanguage.bind(this);
        this.setLanguageValue=this.setLanguageValue.bind(this);
        this.setLanguageMasteryValue=this.setLanguageMasteryValue.bind(this);
        this.saveProfile=this.saveProfile.bind(this);
        this.back=this.back.bind(this);
    }
    render(){
        return (
            <article className="wizard">
                <header className="hdr">profile setting</header>
                <section className="sectionDetails">
                    <div className="mainCaption">general details</div>
                    <div className="visual"><img src={this.state.mainImg ? this.state.mainImg : "https://firebasestorage.googleapis.com/v0/b/runningvscity.appspot.com/o/runnerAvatar.png?alt=media&token=a45a2316-6b1b-45c7-b935-a9a82d1984bd"} className="userMainImg"/></div>
                    <div className="bottomMe2"><input id="eUserMainImgSelector" type="file"/><button onClick={this.uploadImg}>upload img</button></div>
                    <div className="cluster">
                        <div className="inputWrapper"><input id="eName" type="text" className="input" placeholder="your name" value={this.state.user.name} onChange={this.setNameValue} maxLength="255"/></div>
                        <div className="inputWrapper">
                            <select id="eGender" className="input" value={this.state.user.gender} onChange={this.setGenderValue}>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select>
                        </div>
                    </div>
                    <div className="inputWrapper">
                        <select id="eCity" className="input" value={this.state.user.cityId} onChange={this.setCityValue}>
                            <option value="-1">select a city please</option>
                            {
                                this.props.cities.map((oCity,iIndex)=>{
                                    return <option key={iIndex} value={oCity.id}>{Helper.fetchCityById(oCity.id).city+" - "+Helper.fetchCityById(oCity.id).country}</option>;
                                })
                            }
                        </select>
                    </div>
                    <div className="cluster">
                        <div className="inputWrapper"><span className="boldMe">10 km avg:&nbsp;</span><input id="e10KmAvg" type="text" className="input" placeholder="hh:mm" value={this.state.user["10 km avg"]} onChange={this.set10KmAvgValue} maxLength="5"/>&nbsp;hr</div>
                        <div className="inputWrapper"><span className="boldMe">20 km avg:&nbsp;</span><input id="e20KmAvg" type="text" className="input" placeholder="hh:mm" value={this.state.user["20 km avg"]} onChange={this.set20KmAvgValue} maxLength="5"/>&nbsp;hr</div>
                    </div>
                </section>
                <section className="sectionDetails">
                        <div className="mainCaption">about myself</div>
                        <div className="inputWrapper"><textarea id="eAboutMyself" className="input" placeholder="you can write about yourself in more details" value={this.state.user["about myself"]} onChange={this.setAboutMyselfValue}></textarea></div>
                </section>
                <section className="sectionDetails">
                        <div className="mainCaption">share your passion - why do you like running (or any other sporting activity) ?</div>
                       {!this.state.isInitialVidOn && <div id="eVideoRecorderWrapperWrapper"><div id="eVideoRecorderWrapper" className="input">
                            <VideoRecorder 
                                    isOnInitially
                                    replayVideoAutoplayAndLoopOff
                                    showReplayControls
                                    onRecordingComplete={videoBlob => {
                                    
                                        this.uploadVidBtn.current.removeAttribute("disabled");
                                        this.uploadVidBtn.current.addEventListener("click",this.uploadVid);
                                        this.setState({
                                            mainVidBlob:videoBlob,
                                        });
                                    }} 
                                    timeLimit={30000}
                            />
                        </div> 
                        <button disabled ref={this.uploadVidBtn}>i like it. use this video</button></div>}

                        {this.state.isInitialVidOn && <div><video id="eVideoRecorderWrapper" className="input" controls src={this.state.initialVidSrc} playsinline />
                        <button onClick={()=>this.setRecMode()}>replace video</button></div>}

                </section>
                <section id="eLanguagesInProfile" className="sectionDetails">
                        <div className="mainCaption">languages</div>
                        {
                            this.state.user.languages && this.state.user.languages.map((oLanguage,iIndex)=>{
                                return (
                                    <div key={iIndex} className="cluster">
                                        <div className="inputWrapper">
                                            <select className="input" value={oLanguage.languageId} onChange={(e)=>this.setLanguageValue(e,iIndex)}>
                                                <option value="-1">select a language</option>
                                                {
                                                    this.props.languages.map((oLng,iIndex)=>{
                                                        return <option key={iIndex} value={oLng.id}>{Helper.fetchLanguageById(oLng.id)}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="inputWrapper">
                                            <select className="input" value={oLanguage.mastery} onChange={(e)=>this.setLanguageMasteryValue(e,iIndex)}>
                                                <option value="-1">what is your mastery level</option>
                                                {
                                                    this.props.languageMastery.map((oMastery,iIndex)=>{
                                                        return <option key={iIndex} value={oMastery.id}>{Helper.fetchLanguageMasteryById(oMastery.id)}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <button className="btnAsLink" onClick={()=>this.removeLanguage(iIndex)}>remove</button>
                                    </div>
                                );
                            })
                        }
                        <button className="btnAsLink" onClick={this.addLanguage}>add</button>
                </section>
                <div className="btnsWrapper">
                        <div id="eSaveBtn" className="btn" onClick={this.saveProfile}>Save</div>
                        <div id="eCancelSaveBtn" className="btn" onClick={this.back}>Cancel</div>
                </div>
            </article>
        )   
    }
    componentDidMount(){
        this.setState({
            user:this.props.user,
        })
        this.downloadImg(this.props.user.imgName);
        this.downloadVid();
/*
        document.querySelector("#eVideoRecorderWrapper video").src="https://firebasestorage.googleapis.com/v0/b/runningvscity.appspot.com/o/3uYGJRwsdkazSNgPp1RchAYJB8D2%2Cwebm?alt=media&token=c318584b-5e4d-41ea-afc6-db0410276a90";
*/
    }
    set10KmAvgValue(e){
        const sVal=e.target.value;
        if (Helper.isTimeValid(sVal)){
            this.setState((prevState,props)=>{
                return {
                    user:{
                        ...prevState.user,
                        "10 km avg":sVal,
                    }
                }
            });
        }
    }
    set20KmAvgValue(e){
        const sVal=e.target.value;
        if (Helper.isTimeValid(sVal)){
            this.setState((prevState,props)=>{
                return {
                    user:{
                        ...prevState.user,
                        "20 km avg":sVal,
                    }
                }
            });
        }
    }
    setAboutMyselfValue(e){
        const sVal=e.target.value;
        this.setState((prevState,props)=>{
            return {
                user:{
                    ...prevState.user,
                    "about myself":sVal,
                }
            }
        });
    }
    setCityValue(e){
        const sVal=e.target.value;
        this.setState((prevState,props)=>{
            return {
                user:{
                    ...prevState.user,
                    cityId:sVal,
                }
            }
        });
    }
    setGenderValue(e){
        const sVal=e.target.value;
        this.setState((prevState,props)=>{
            return {
                user:{
                    ...prevState.user,
                    gender:sVal,
                }
            }
        });
    }
    setNameValue(e){
        const sVal=e.target.value;
        this.setState((prevState,props)=>{
            return {
                user:{
                    ...prevState.user,
                    name:sVal,
                }
            }
        });
    }
    saveProfile(){
        db.collection("runners").doc(firebase.auth().currentUser.uid).set(this.state.user)
        .then(()=>{
            //docRef = id of new doc
                this.props.replaceUser(this.state.user);
                this.props.replaceRunner(this.state.user);
                alert ("the profile was successfully edited");
                this.props.setPage(this.props.prevPg);
        })
        .catch((error)=>{
            console.log("error when trying to edit profile details via the db. error is - "+error);
        });
    }
    back(){
        this.props.setPage(this.props.prevPg);
    }
    uploadVid(){
        const sVidId=this.props.user.id+(Helper.isIphone() ? ".mp4" : ".webm");
        firebase.storage().ref().child(sVidId).put(this.state.mainVidBlob)
        .then((snapshot)=>{
            alert ("upload succeeded");
        });
    }
    downloadVid(){
        firebase.storage().ref().child(this.props.user.id+(Helper.isIphone() ? ".mp4" : ".webm")).getDownloadURL()
        .then((sUrl)=>{
            if (sUrl){
                this.setState({
                    isInitialVidOn:true,
                    initialVidSrc:sUrl,
                })
            };
            console.log("downloadMovie succeeded");
        })
        .catch((error)=>{
            console.log("downloadingMovie in ProfileMaekr component failed. error is - ",error);
        });
    }
    setRecMode(){
        this.setState({
            isInitialVidOn:false,
        })
    }
    uploadImg(){
        if (!document.getElementById("eUserMainImgSelector").files[0]){
            alert ("choose an img first");
            return;
        };
        const sImgId=this.props.user.id+",jpg";
        firebase.storage().ref().child(sImgId).put(document.getElementById("eUserMainImgSelector").files[0])
        .then((snapshot)=>{
           this.setState((prevState,props)=>{
                return {
                    user:{
                        ...prevState.user,
                        imgName:sImgId,
                    },
                }
            });
            console.log ("upload succeeded");
            this.downloadImg(sImgId);
        })
        .catch((error)=>{
            console.log("uploading img in profile failed. error is - ",error);
        });

    }
    downloadImg(sImgName){
        if (sImgName){
            firebase.storage().ref().child(sImgName).getDownloadURL()
            .then((sUrl)=>{
                if (sUrl){
                    this.setState({
                        mainImg:sUrl,
                    })
                };
                console.log("downloadImg succeeded");
            })
            .catch((error)=>{
                console.log("downloadingImg in ProfileMaekr component failed. error is - ",error);
            });
        }
    }
    addLanguage(){
        const  arLanguages = this.state.user.languages || [];
        this.setState((prevState,props)=>{
            return {
                user:{
                    ...prevState.user,
                    languages:[...arLanguages,{languageId:"-1",mastery:"-1",}],
                }
            }
        })
    }
    removeLanguage(iIndex){
        let arLanguages=this.state.user.languages.slice();
        arLanguages.splice(iIndex,1);
        this.setState((prevState,props)=>{
            return {
                user:{
                    ...prevState.user,
                    languages:arLanguages,
                }
            }
        });
    }
    setLanguageValue(e,iIndex){
        var arLanguages=this.state.user.languages.slice();
        arLanguages[iIndex].languageId=e.target.value;
        this.setState((prevState,props)=>{
            return {
                user:{
                    ...prevState.user,
                    languages:arLanguages,
                }
            }
        });
    }
    setLanguageMasteryValue(e,iIndex){
        var arLanguages=this.state.user.languages.slice();
        arLanguages[iIndex].mastery=e.target.value;
        this.setState((prevState,props)=>{
            return {
                user:{
                    ...prevState.user,
                    languages:arLanguages,
                }
            }
        });
    }
}

const mapStateToProps = (state)=>{
    return {
        prevPg:state.upState.prevPg,
        cities:state.cities.cities,
        languages:state.languages.languages,
        languageMastery:state.languageMastery.languageMastery,
        user:state.upState.user,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        setPage:sPg=>{dispatch(setPage(sPg))},
        replaceUser:oUser=>{dispatch(replaceUser(oUser))},
        replaceRunner:oRunner=>{dispatch(replaceRunner(oRunner))},
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProfileMaker);