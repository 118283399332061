import {SET_DATES_PAGE,PROFILE_PAGE} from "../constants.js";
import React,{Component} from "react";
import {connect} from "react-redux";
import {setPage,setSelectedActivityId} from "../redux/actions/state";
import {replaceActivity,addActivity} from "../redux/actions/activities";
import firebase,{db} from "./firebase";
import Helper from "../helper";
import Dropzone from "react-dropzone";
import Compress from "browser-image-compression";

class ActivityMaker extends Component {
    constructor(props){
        super(props);
        this.state={
            activity:{
                arActivityVisualItems:[],
                activityType:-1,
                name:"",
                duration:"",
                pace:"",
                distance:"",
                what:"",
                where:"",
                departureLocationAddress:"",
                departureLocationDetails:"",
                price:"",
                maxNumOfParticipants:4,
            },
            arFiles:[],
            arUrls:[],
            bAskForSaveBeforeLeaving:false,
        };
        this.setActivityTypeValue=this.setActivityTypeValue.bind(this);
        this.setNameValue=this.setNameValue.bind(this);
        this.setDurationValue=this.setDurationValue.bind(this);
        this.setPaceValue=this.setPaceValue.bind(this);
        this.setDistanceValue=this.setDistanceValue.bind(this);
        this.setMaxNumOfParticipants=this.setMaxNumOfParticipants.bind(this);
        this.setPriceValue=this.setPriceValue.bind(this);
        this.setWhatValue=this.setWhatValue.bind(this);
        this.setWhereValue=this.setWhereValue.bind(this);
        this.setDepartureLocationAddressValue=this.setDepartureLocationAddressValue.bind(this);
        this.setDepartureLocationDetailsValue=this.setDepartureLocationDetailsValue.bind(this);
        this.saveActivity=this.saveActivity.bind(this);
        this.uploadImg=this.uploadImg.bind(this);
        this.back=this.back.bind(this);
        this.setDates=this.setDates.bind(this);
        this.contWithSetDates=this.contWithSetDates.bind(this);
        this.constructActivityObj=this.constructActivityObj.bind(this);
        this.resetPrice=this.resetPrice.bind(this);
        this.validateFlds=this.validateFlds.bind(this);
        this.handleDrop=this.handleDrop.bind(this);
        this.getImgUrl=this.getImgUrl.bind(this);
        this.delImg=this.delImg.bind(this);
    }
    render(){
        let sPgTitle="";
        if (this.props.selectedActivityId){
            sPgTitle="editing the activity";
        }
        else{
            sPgTitle="adding an activity";
        }
        return (
            <article className="wizard">
                <header className="hdr">{sPgTitle}</header>
                <section className="sectionDetails">
                    <div className="mainCaption">general details</div>
                    <div className="visualSectionWrapper">
                        <Dropzone onDrop={this.handleDrop}>
                            {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({className: "dropzone"})}>
                                <input {...getInputProps()} />
                                <p>drag and drop images or click to select them</p>
                            </div>
                            )}
                        </Dropzone>
                        {
                            this.state.arUrls.map((oUrl,ii)=>{return <div className="visualItmWrapper" key={ii}><img className="visualItm" src={oUrl.sUrl} data-imgname={oUrl.sImgName}/><button className="closeBtn" title="delete image" onClick={this.delImg}></button></div>;})
                        }
                    </div>
                    <select className="input mandatory" value={this.state.activity.activityType} onChange={this.setActivityTypeValue} placeholder="activity type">
                        <option value="-1">select an activity type</option>
                        {
                            this.props.activityTypes.map((oActivityType,iIndex)=>{
                                return <option key={iIndex} value={oActivityType.id}>{Helper.fetchActivityTypeById(oActivityType.id)}</option>;
                            })
                        }
                    </select>
                    <input id="eActivityName" type="text" className="input mandatory" placeholder="activity name" value={this.state.activity.name} onChange={this.setNameValue} maxLength="1000"></input>
                    <div className="cluster">
                        {Helper.isActivityTypeContainDistanceAndPaceDataById(this.state.activity.activityType) && <div className="inputWrapper"><input id="eActivityDistance" type="text" className="input" placeholder="distance" value={this.state.activity.distance} onChange={this.setDistanceValue} maxLength="5"/>&nbsp;km</div>} 
                        {Helper.isActivityTypeContainDistanceAndPaceDataById(this.state.activity.activityType) && <div className="inputWrapper"><input id="eActivityPace" type="text" className="input" placeholder="mm:ss" value={this.state.activity.pace} onChange={this.setPaceValue} maxLength="5"/>&nbsp;<span title="minutes per km">mins per km</span></div>}
                        <div className="inputWrapper"><input id="eActivityDuration" type="text" className="input" placeholder="hh:mm" value={this.state.activity.duration} onChange={this.setDurationValue} maxLength="4" title="total activity duration"/>&nbsp;hr (total activity duration)</div>
                    </div>
                    <div className="inputWrapper"><input id="eMaxNumOfParticipants" type="text" className="input" placeholder="" value={this.state.activity.maxNumOfParticipants} onChange={this.setMaxNumOfParticipants} maxLength="2"/>&nbsp;max num of participants</div>
                    <div className="inputWrapper"><input id="eActivityPrice" type="text" className="input" placeholder="price" value={this.state.activity.price} onChange={this.setPriceValue} maxLength="5"/>&nbsp;{this.props.user.cityId ? Helper.fetchCityById(this.props.user.cityId).coin : "euro"}<button id="bFreeActivity" onClick={this.resetPrice}>FREE</button></div>
                </section>
                <section className="sectionDetails">
                        <div className="mainCaption">about the activity</div>
                        <div className="subCaption">what we are going to do</div>
                        <div className="inputWrapper"><textarea id="eActivityWhat" className="input mandatory" placeholder="what we are going to do" value={this.state.activity.what} onChange={this.setWhatValue}></textarea></div>
                        <div className="subCaption">where we are going to do it</div>
                        <div className="inputWrapper"><textarea id="eActivityWhere" className="input mandatory" placeholder="where we are going to do it" value={this.state.activity.where} onChange={this.setWhereValue}></textarea></div>
                </section>
                <section className="sectionDetails">
                    <div className="mainCaption">point of departure</div>
                    <div className="inputWrapper"><input id="eDepartureLocationAddress" type="text" className="input" placeholder="address" maxLength="255" value={this.state.activity.departureLocationAddress} onChange={this.setDepartureLocationAddressValue}/></div>
                    <div className="inputWrapper"><textarea id="eDepartureLocationDetails" className="input" placeholder="you can add more details regarding the point of departure" value={this.state.activity.departureLocationDetails} onChange={this.setDepartureLocationDetailsValue}></textarea></div>
                </section>
                {this.props.selectedActivityId && <div id="eSetDatesBtn" className="btn" onClick={this.setDates}>Open Diary</div>}
                <div className="btnsWrapper">
                        {!this.props.selectedActivityId && <div id="eSaveAndSetDatesBtn" className="btn" onClick={this.setDates}>Save And Set Dates</div>}
                        <div id="eSaveBtn" className="btn" onClick={()=>this.saveActivity(false)}>Save</div>
                        <div id="eCancelSaveBtn" className="btn" onClick={this.back}>Cancel</div>
                </div>
            </article>
        )
    }
    componentDidMount(){
        const _self=this;
        if (!this.props.user.cityId){
            alert ("you have to select a city in your profile page before creating a new activity");
            this.props.setPage(PROFILE_PAGE);
        }
        if (this.props.selectedActivityId){
            const oActivity=Helper.fetchActivityById(this.props.selectedActivityId);
            this.setState({
                activity:{
                    arActivityVisualItems:oActivity.arActivityVisualItems,
                    activityType:oActivity.activityType,
                    distance:oActivity.distance,
                    departureLocationAddress:oActivity.location.address,
                    departureLocationDetails:oActivity.location.details,
                    pace:oActivity["minutes per km"],
                    name:oActivity.name,
                    price:oActivity.price,
                    duration:oActivity.time,
                    maxNumOfParticipants:oActivity.maxNumOfParticipants,
                    what:oActivity.what,
                    where:oActivity.where,
                }
            });
            oActivity.arActivityVisualItems.forEach((oItm,ii)=>{
                _self.getImgUrl(oItm.url);
            });
        }
        document.querySelectorAll(".wizard input,.wizard textarea").forEach(elm=>{
            elm.addEventListener("change",e=>{
                _self.setState({
                    bAskForSaveBeforeLeaving:true,
                })
            })
        });
    }
    componentWillUnmount(){
        if (this.state.bAskForSaveBeforeLeaving && window.confirm ("save updated activity ?")){
            this.saveActivity();
        };
    }
    setActivityTypeValue(e){
        const sActivityType=e.target.value;
        this.setState((prevState,props)=>{
            return {
                ...prevState,
                activity:{
                    ...prevState.activity,
                    activityType:sActivityType,
                }
            }
        });
    }
    setNameValue(e){
        const sName=e.target.value;
        this.setState((prevState,props)=>{
            return {
                ...prevState,
                activity:{
                    ...prevState.activity,
                    name:sName,
                }
            }
        });
    }
    setDistanceValue(e){
        const sDistance=e.target.value;
        if (!isNaN(sDistance)){
            this.setState((prevState,props)=>{
                return {
                    activity:{
                        ...prevState.activity,
                        distance:sDistance,
                    }
                }
            });
        }
    }
    setPaceValue(e){
        const sPace=e.target.value;
        if (Helper.isTimeValid(sPace)){
            this.setState((prevState,props)=>{
                return {
                    activity:{
                        ...prevState.activity,
                        pace:sPace,
                    }
                }
            });
        }
    }
    setDurationValue(e){
        const sDuration=e.target.value;
        if (Helper.isTimeValid(sDuration)){
            this.setState((prevState,props)=>{
                return {
                    activity:{
                        ...prevState.activity,
                        duration:sDuration,
                    }
                }
            });
        }
    }
    setMaxNumOfParticipants(e){
        const iMaxNumOfParticipants=!isNaN(e.target.value) ? parseInt(e.target.value,10) : 4;
        this.setState((prevState,props)=>{
            return {
                ...prevState,
                activity:{
                    ...prevState.activity,
                    maxNumOfParticipants:iMaxNumOfParticipants,
                }
            }
        });
    }
    setPriceValue(e){
        const sPrice=e.target.value;
        if (!isNaN(sPrice)){
            this.setState((prevState,props)=>{
                return {
                    ...prevState,
                    activity:{
                        ...prevState.activity,
                        price:sPrice,
                    }
                }
            });
        }
    }
    setWhatValue(e){
        const sWhat=e.target.value;
        this.setState((prevState,props)=>{
            return {
                ...prevState,
                activity:{
                    ...prevState.activity,
                    what:sWhat,
                }
            }
        });
    }
    setWhereValue(e){
        const sWhere=e.target.value;
        this.setState((prevState,props)=>{
            return {
                ...prevState,
                activity:{
                    ...prevState.activity,
                    where:sWhere,
                }
            }
        });
    }
    setDepartureLocationAddressValue(e){
        const sDepartureLocationAddress=e.target.value;
        this.setState((prevState,props)=>{
            return {
                ...prevState,
                activity:{
                    ...prevState.activity,
                    departureLocationAddress:sDepartureLocationAddress,
                }
            }
        });
    }
    setDepartureLocationDetailsValue(e){
        const sDepartureLocationDetails=e.target.value;
        this.setState((prevState,props)=>{
            return {
                ...prevState,
                activity:{
                    ...prevState.activity,
                    departureLocationDetails:sDepartureLocationDetails,
                }
            }
        });
    }
    constructActivityObj(){
        let oActivity={};
        oActivity.activityType=this.state.activity.activityType.toString();
        oActivity.arActivityVisualItems=this.state.activity.arActivityVisualItems;
        oActivity.city=this.props.user.cityId.toString();
        oActivity.distance=this.state.activity.distance.toString();
        oActivity.initiator=this.props.user.id.toString();
        oActivity.location={
            address:this.state.activity.departureLocationAddress.toString(),
            details:this.state.activity.departureLocationDetails.toString(),
        };
        oActivity["minutes per km"]=this.state.activity.pace.toString();
        oActivity.name=this.state.activity.name.toString();
        oActivity.price=this.state.activity.price.toString();
        oActivity.time=this.state.activity.duration.toString();
        oActivity.what=this.state.activity.what.toString();
        oActivity.maxNumOfParticipants=this.state.activity.maxNumOfParticipants.toString();
        oActivity.where=this.state.activity.where.toString();
        
        return oActivity;
    }
    validateFlds(){
        let bValid=true;
        document.querySelectorAll(".wizard .mandatory").forEach((elm,ii)=>{
            if (bValid && (elm.value.trim()==="" || elm.value.trim()==="-1")){
                alert ("the '"+elm.getAttribute("placeholder")+"' field is mandatory");
                bValid=false;
                elm.focus();
            }
        });
        return bValid;
    }
    saveActivity(bOpenSetDatesInterface){
        if (!this.validateFlds())return;
        let oActivity=this.constructActivityObj();
        if (this.props.selectedActivityId){
            const sActivityId=this.props.selectedActivityId;
            oActivity.id=sActivityId;
            db.collection("activities").doc(sActivityId).update({...oActivity})
            .then((docRef)=>{
                //docRef = id of new doc
                    oActivity.oScheduledAct=Helper.fetchActivityById(sActivityId).oScheduledAct; //we don't want to override recent state in db (things could have changed from time of first db retrieval and the current save)
                    oActivity.comments=Helper.fetchActivityById(sActivityId).comments; // see above comment.
                    this.props.replaceActivity(oActivity);
                    alert ("save completed");
                    this.setState({
                        bAskForSaveBeforeLeaving:false,
                    });
                    this.props.setPage(this.props.prevPg);
            })
            .catch((error)=>{
                console.log("error when trying to save activity details via the db. error is - "+error);
            });
    
        }
        else{
            db.collection("activities").add(oActivity)
            .then((docRef)=>{
                //docRef = id of new doc
                oActivity.id=docRef.id;
                this.props.addActivity(oActivity);
                this.props.setSelectedActivityId(oActivity.id);
                if (bOpenSetDatesInterface){
                    this.contWithSetDates();
                }else{
                    alert ("the activity was successfully added");
                    this.setState({
                        bAskForSaveBeforeLeaving:false,
                    })
                    this.props.setPage(this.props.prevPg);
                }
            })
            .catch((error)=>{
                console.log("error when trying to add an activity to the db. error is - "+error);
            });
    
        }
    }
    back(){
        this.setState({
            bAskForSaveBeforeLeaving:false,
        })
        this.props.setPage(this.props.prevPg);
    }
    uploadImg(arFiles){
       const _self=this;
        arFiles.forEach(function(oFile,ii){
            const sImgId=_self.props.user.id+"_"+(new Date()).getTime()+",jpg";

            // here we have the ofile object and we can compress it. we must do it in order for humanity to survivee.

            const options = {
                // As the key specify the maximum size
                // Leave blank for infinity
                maxSizeMB: 0.1,
                // Use webworker for faster compression with
                // the help of threads
                useWebWorker: true
            }

            Compress(oFile, options)
            .then(compressedBlob => {
                // Compressed file is of Blob type
                // You can drop off here if you want to work with a Blob file
//                console.log(compressedBlob)
 
                // If you want to work with the File
                // Let's convert it here, by adding a couple of attributes
                compressedBlob.lastModifiedDate = new Date()
 
                // Conver the blob to file
                const convertedBlobFile = new File([compressedBlob], oFile.name, { type: oFile.type, lastModified: Date.now()})
                // Here you are free to call any method you are gonna use to upload your file example uploadToCloudinaryUsingPreset(convertedBlobFile)
                firebase.storage().ref().child(sImgId).put(convertedBlobFile)
                .then((snapshot)=>{
                    let arVisualItems=_self.state.activity.arActivityVisualItems.slice();
                    arVisualItems.push({isMain:true,order:1,url:sImgId});
                    _self.setState((prevState,props)=>{
                        return {
                            activity:{
                                ...prevState.activity,
                                arActivityVisualItems:arVisualItems,
                            },
                        }
                    });
                    _self.getImgUrl(sImgId);
                    console.log ("upload succeeded");
                })
                .catch((error)=>{
                    console.log("uploading img in profile failed. error is - ",error);
                });
    



            })
            .catch(e => {
                // Show the user a toast message or notification that something went wrong while compressing file
            })
        })
    }
    getImgUrl(sImgName){
        if (!!sImgName){
            firebase.storage().ref().child(sImgName).getDownloadURL()
            .then((sUrl)=>{
                if (sUrl){
                    let arUrls=this.state.arUrls.slice();
                    arUrls.push({sUrl,sImgName});
                    this.setState({
                        arUrls,
                    })
                };
            })
            .catch((error)=>{
                console.log("getImgUrl in ActivityMaker component failed. error is - ",error);
            });
        }
    }
    setDates(){
        if (this.props.selectedActivityId){
            this.contWithSetDates();
        }
        else{
            this.saveActivity(true);
        };
    }
    contWithSetDates(){
        this.props.setPage(SET_DATES_PAGE,false);
    }
    resetPrice(){
        document.getElementById("eActivityPrice").value="0";
    }
    handleDrop(arFiles){
        if (!/[.](webp|jpg|gif|png)$/.test(arFiles[0].path)){
            alert ("only images please.");
        }
        else if (this.state.activity.arActivityVisualItems.length>4){
            alert ("you can't add more than 5 images");
        }
        else{
            this.setState({
                arFiles:this.state.arFiles.slice().concat(arFiles),
                bAskForSaveBeforeLeaving:true,
            })
            this.uploadImg(arFiles);
        }
    }
    delImg(e){
        const eElm=e.target || e.srcElement;
        let ii;
        const sImgId=eElm.parentNode.querySelector(".visualItm").getAttribute("data-imgname");
        let arActivityVisualItems=this.state.activity.arActivityVisualItems.slice();
        for (ii=0;ii<arActivityVisualItems.length;ii++){
            if (arActivityVisualItems[ii].url===sImgId){
                arActivityVisualItems.splice(ii,1);
                eElm.parentNode.classList.add("hideMe");
                this.setState((prevState,props)=>{
                    return {
                        ...prevState,
                        activity:{
                            ...prevState.activity,
                            arActivityVisualItems,
                        },
                        bAskForSaveBeforeLeaving:true,
                    }
                });
                break;
            }
        }
    }
}

const mapStateToProps = (state)=>{
    return {
        prevPg:state.upState.prevPg,
        activityTypes:state.activityType.activityType,
        user:state.upState.user,
        selectedActivityId:state.upState.selectedActivityId,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        setPage:(sPg,bSetPrev)=>{dispatch(setPage(sPg,bSetPrev))},
        setSelectedActivityId:sId=>{dispatch(setSelectedActivityId(sId))},
        replaceActivity:oActivity=>{dispatch(replaceActivity(oActivity))},
        addActivity:oActivity=>{dispatch(addActivity(oActivity))},
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ActivityMaker);