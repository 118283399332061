import chatDbManager from "./chatDbManager";
import {CHAT_PAGE,APPETIZER_PAGE,SIGNUP_PAGE} from "./constants.js";
import firebase,{db} from "./components/firebase";
import store from "./redux/store/configureStore";

export const setConnectionTracking = (userId)=>{
    const uid=firebase.auth().currentUser.uid;

    var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
    var userStatusFirestoreRef = firebase.firestore().doc('/status/' + uid);
    

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    var isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
    
    var isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
    var isOfflineForFirestore = {
        state: 'offline',
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
    };
    
    var isOnlineForFirestore = {
        state: 'online',
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
    };
    
    firebase.database().ref('.info/connected').on('value', function(snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() == false) {
            userStatusFirestoreRef.set(isOfflineForFirestore);
            return;
        };
    
        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
    
            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            userStatusDatabaseRef.set(isOnlineForDatabase);
            // We'll also add Firestore set here for when we come online.
            userStatusFirestoreRef.set(isOnlineForFirestore);
                        
        });
    });

    
}
export const setChatTracking=(context)=>{
    const self=context;
    db.collection("runners").doc(firebase.auth().currentUser.uid).onSnapshot((doc)=>{
        const oRunner=doc.data();
        switch (oRunner.chatState){
            case "chatRequest":
                if (window.confirm("another runner wants to talk with you. do you agree ?")){
                    self.props.setChatterId(oRunner.chatterId);
                    self.props.setPage(CHAT_PAGE);
                    chatDbManager.updateChatData("chat",oRunner.chatterId,"");
                }
                else{
                    chatDbManager.updateChatData("refuseChat",oRunner.chatterId,"");
                    chatDbManager.updateChatData("free",firebase.auth().currentUser.uid,"");
                }
                break;
            case "chat":
                self.props.setChatterId(oRunner.chatterId);
                self.props.setPage(CHAT_PAGE);
                if (oRunner.chatTxt.trim()!=="")self.props.setChatText("you",oRunner.chatTxt);
                break;
            case "endChat":
                alert ("the chat was ended");
                chatDbManager.updateChatData("free",firebase.auth().currentUser.uid,"");
                self.props.setPage(self.props.prevPg);
                break;
            case "free":
                self.props.setChatterId(null);
                break;
            case "refuseChat":
                alert ("the chat is refused, dude");
                chatDbManager.updateChatData("free",firebase.auth().currentUser.uid,"");
                break;
            default:
                break;
        }
    })
}
export const startTracking=(context)=>{
    const self=context;
    firebase.auth().onAuthStateChanged((user) => {
        if (user && user.emailVerified) {
            db.collection("runners").doc(user.uid).get()
            .then((doc)=>{
                self.props.connectUser(doc.data().name,{id:doc.id,...doc.data()});
                self.props.setPage(self.props.prevPg);
/*
                setConnectionTracking(user.uid);
                db.collection("runners").doc(user.uid).set({
                    chatState:"free",
                    chatterId:"",
                    chatTxt:"",
                },{merge:true})
                .then(()=>{
                    setChatTracking(context);
                })
                .catch((error)=>{
                    console.error("error msg when trying to reset chat data. error is",error);
                });
*/
            })
            .catch((error)=>{
                console.error("error msg when trying to load runner from firestore in login pg. error is",error);
            })
        }
        else if (user){
            alert ("we sent you an email verification request. you can login after verification");
            if (self.props.pg===SIGNUP_PAGE && !!self.props.userNm){
                db.collection("runners").doc(user.uid).set({
                    name:self.props.userNm,
                    email:firebase.auth().currentUser.email,
                },{merge:true})
                .then(function(){
                })
                .catch(function(oErr){
                });
                self.props.setPage(APPETIZER_PAGE);
            }

            window.setTimeout(function(){
                firebase.auth().signOut();
            },1000)
        }
    });
}
export const getRunnerResetObj = ()=>{
    let oRunnerResetObject= {
    }
    oRunnerResetObject["10 km avg"]="";
    oRunnerResetObject["20 km avg"]="";
    oRunnerResetObject["about myself"]="";
    oRunnerResetObject["chatState"]="";
    oRunnerResetObject["chatTxt"]="";
    oRunnerResetObject["chatterId"]="";
    oRunnerResetObject["cityId"]="";
    oRunnerResetObject["languages"]=null;
    oRunnerResetObject["messagesReceived"]=null;
    oRunnerResetObject["name"]="";
    oRunnerResetObject["products"]=null;
    oRunnerResetObject["cityName"]="";
    return oRunnerResetObject;
}


class Helper{

    static fetchLanguageById(sId){
        let sLanguage="";
        store.getState().languages.languages.forEach((oLanguage)=>{
            if (sId===oLanguage.id){
                sLanguage=oLanguage.name;
                return false;
            }
        })
        return sLanguage;
    }
    static fetchRunnerById(runnerId){
        let oRunner=null;
        store.getState().runners.runners.forEach(element => {
            if (element.id===runnerId){
                oRunner=element;
                return false;
            }
        });
        return oRunner;
    }
    static fetchActivityById(activityId){
        let oActivity=null;
        store.getState().activities.activities.forEach(element => {
            if (element.id===activityId){
                oActivity=element;
                return false;
            }
        });
        return oActivity;
    }
    static fetchCityById(cityId){
        let oCity=null;
        store.getState().cities.cities.forEach(element => {
            if (element.id===cityId){
                oCity=element;
                return false;
            }
        });
        return oCity;
    }

    static fetchActivityScopeById(itmId){
        let sVal="";
        store.getState().activityScope.activityScope.forEach(element => {
            if (element.id===itmId){
                sVal=element.name;
                return false;
            }
        });
        return sVal!=="" ? sVal : "the bigger the merrier";
    }
    static fetchActivityTypeById(itmId){
        let sVal="";
        store.getState().activityType.activityType.forEach(element => {
            if (element.id===itmId){
                sVal=element.name;
                return false;
            }
        });
        return sVal!=="" ? sVal : "running as a rabbit";
    }
    static isActivityTypeContainDistanceAndPaceDataById(itmId){
        let bVal=false;
        store.getState().activityType.activityType.forEach(element => {
            if (element.id===itmId){
                bVal = !!element.bContainingDistanceAndPaceData;
                return false;
            }
        });
        return bVal;
    }
    static fetchAvailabilityById(itmId){
        let sVal="";
        store.getState().availability.availability.forEach(element => {
            if (element.id===itmId){
                sVal=element.name;
                return false;
            }
        });
        return sVal!=="" ? sVal : "every where mon amour";
    }

    static fetchLanguageMasteryById(itmId){
        let sVal="";
        store.getState().languageMastery.languageMastery.forEach(element => {
            if (element.id===itmId){
                sVal=element.name;
                return false;
            }
        });
        return sVal!=="" ? sVal : "good";
    }

    static fetchLocationById(itmId){
        let sVal="";
        store.getState().location.location.forEach(element => {
            if (element.id===itmId){
                sVal=element.name;
                return false;
            }
        });
        return sVal!=="" ? sVal : "you name it, i'll be there";
    }
    static isTimeValid(sTime){
        return /^(([0-1]?[0-9]|2[0-4])(:|:[0-5]|:[0-5][0-9]){0,2})$|^\s*$/.test(sTime);
    }
    static getWeekDayName(oDt){
        const arDays=["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];
        return arDays[oDt.getDay()];
    }
    static getMonthShortName(oDt){
        const arMonths=["jan","feb","mar","april","may","june","july","aug","sep","oct","nov","dec"];
        return arMonths[oDt.getMonth()];
    }
    static sendEmail(sFrom,sTxt,sEmail){
        const sMsgHtml="<html><head></head><body style='text-align:left;font-family:arial;font-size:16px;'><div>from: <span style='font-weight:bold;'>"+sFrom+"</span></div><div>content: "+sTxt+"</div>";
        fetch("https://us-central1-sendemail-7cc42.cloudfunctions.net/sendMail?dest="+sEmail+"&txt="+sMsgHtml)
        .then(oResponse=>{
        })
        .catch(sErr=>{
            console.log ("in sendEmail function. error in sending a msg. err="+sErr);
        });
    }
    static isMobile2(){
        return window.matchMedia && window.matchMedia("(max-device-width:768px)").matches;
    }
    static isIphone(){
        return /iPhone/i.test(navigator.userAgent);
    }
}

export default Helper;
