
import {NONE,FETCH_CITY_BEGIN,FETCH_CITY_SUCCESS,FETCH_CITY_ERROR} from "../../constants.js";
const initialState = {
    action:NONE,
    cities:[],
}


const citiesReducer = (state=initialState,{type,cities})=>{
    switch (type){
        case FETCH_CITY_BEGIN:
            return {
                ...state,
                action:type,
            }
        case FETCH_CITY_SUCCESS:
            return {
                ...state,
                action:type,
                cities,
            }
        default:
            return state;
    }
}

export default citiesReducer;