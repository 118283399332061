import {FETCH_ONE_RUNNER_SUCCESS} from "../constants";
import React,{Component} from "react";
import {connect} from "react-redux";
import firebase,{db} from "./firebase";
import chatDbManager from "../chatDbManager";
import {fetchOneRunner} from "../redux/actions/runners";
import {setPage} from "../redux/actions/state";
import Helper from "../helper";

class RunnerPage extends Component {
    constructor(props){
        super(props);
        this.downloadImg=this.downloadImg.bind(this);
        this.openSendMsgInterface=this.openSendMsgInterface.bind(this);
        this.sendMsg=this.sendMsg.bind(this);
        this.setMsg=this.setMsg.bind(this);
        this.sendChatRequest=this.sendChatRequest.bind(this);
        this.back=this.back.bind(this);
        this.cancelSendMsg=this.cancelSendMsg.bind(this);
        this.state = {
            isOpenSendMsgInterface:false,
            msg:"",
            runnerImgUrl:"",
        }
    }
    render(){
        const sUnspecifiedTxt="unspecified";
        if (this.props.loadStatus===FETCH_ONE_RUNNER_SUCCESS && Helper.fetchRunnerById(this.props.runnerId)){
            return (
                <div className="runnerPage">
                    <div className="visual"><img src={this.state.runnerImgUrl || "https://firebasestorage.googleapis.com/v0/b/runningvscity.appspot.com/o/runnerAvatar.png?alt=media&token=a45a2316-6b1b-45c7-b935-a9a82d1984bd"} className="runnerMainImg"/></div>
                    <div className="sectionDetails">
                        <div className="cluster topMe1">
                            <span className="itm">{Helper.fetchRunnerById(this.props.runnerId).name || sUnspecifiedTxt+ " name"}</span>
                            <span className="delimiter">-</span>
                            <span className="itm">{Helper.fetchRunnerById(this.props.runnerId).gender || sUnspecifiedTxt+ " gender"}</span>                        
                        </div>
                        <div className="cluster">
                            <span className="itm">{Helper.fetchRunnerById(this.props.runnerId).cityId ? Helper.fetchCityById(Helper.fetchRunnerById(this.props.runnerId).cityId).city : sUnspecifiedTxt+" city"}</span>
                            <span className="delimiter">-</span>
                            <span className="itm">{Helper.fetchRunnerById(this.props.runnerId).cityId ? Helper.fetchCityById(Helper.fetchRunnerById(this.props.runnerId).cityId).country : sUnspecifiedTxt+" country"}</span>
                        </div>
                        <div className="cluster">
                            <span className="itm"><span className="boldMe">10 km avg:</span>&nbsp;{Helper.fetchRunnerById(this.props.runnerId)["10 km avg"] ? Helper.fetchRunnerById(this.props.runnerId)["10 km avg"]+"hr" : sUnspecifiedTxt}</span>
                            <span className="delimiter">-</span>
                            <span className="itm"><span className="boldMe">20 km avg:</span>&nbsp;{Helper.fetchRunnerById(this.props.runnerId)["20 km avg"] ? Helper.fetchRunnerById(this.props.runnerId)["20 km avg"]+"hr" : sUnspecifiedTxt}</span>
                        </div>
                        <div className="cluster">
                        {
                            Helper.fetchRunnerById(this.props.runnerId).languages && 
                            Helper.fetchRunnerById(this.props.runnerId).languages.map((oLanguage,iIndex)=>{
                                let eDelimiter=<span></span>;
                                if (iIndex+1<Helper.fetchRunnerById(this.props.runnerId).languages.length)eDelimiter=<span className="delimiter">-</span>;
                                return (<span key={iIndex}><span>{Helper.fetchLanguageMasteryById(oLanguage.mastery)}</span>&nbsp;<span className="boldMe">{Helper.fetchLanguageById(oLanguage.languageId)}</span>{eDelimiter}</span>);
                            })
                        }
                        </div>
                    </div>
                    <div className="sectionDetails">
                        <div className="mainCaption">about myself</div>
                        <div className="details">{Helper.fetchRunnerById(this.props.runnerId)["about myself"] || sUnspecifiedTxt}</div>
                    </div>

                    {<input type="button" className="backBtn rightMe1" value="back" onClick={this.back}/>}
                    {this.props.isConnected &&  this.props.runnerId!==firebase.auth().currentUser.uid && <button className="btnAsLink rightMe1" onClick={this.openSendMsgInterface}>send IM</button>}
                    {this.props.connected.includes(this.props.runnerId) && this.props.runnerId!==firebase.auth().currentUser.uid && Helper.fetchRunnerById(this.props.runnerId).chatState==="free" && <button className="btnAsLink rightMe1" onClick={this.sendChatRequest}>chat me up</button>}
                    {this.state.isOpenSendMsgInterface && <div>
                        <textarea className="topMe1" value={this.state.msg} onChange={this.setMsg}></textarea>
                        <button className="btnAsLink leftMe1" onClick={this.sendMsg}>send</button>
                        <button className="btnAsLink" onClick={this.cancelSendMsg}>cancel</button>
                    </div>}
                </div>
            )
    
        }else{
            return (
                <div className="runnerPage">
                    didn't find the reuqested profile
                </div>
            )
        }
    }
    componentDidMount(){
        this.props.fetchOneRunner(this.props.runnerId);
    }
    componentDidUpdate(){
        if (this.props.loadStatus===FETCH_ONE_RUNNER_SUCCESS && !this.state.isImgLoaded){
            this.downloadImg(Helper.fetchRunnerById(this.props.runnerId).imgName,"runnerImgUrl");
        }
    }
    downloadImg(sImgName,sStateParam){
        if (sImgName){
            firebase.storage().ref().child(sImgName).getDownloadURL()
            .then((sUrl)=>{
                let oChanges={};
                if (sUrl){
                    oChanges[sStateParam]=sUrl;
                }
                else{
                    oChanges[sStateParam]="";
                };
                oChanges.isImgLoaded=true;
                this.setState(oChanges);
                console.log("downloadImg succeeded");
            })
            .catch((error)=>{
                console.log("downloadingImg in ActivityCard component failed. error is - ",error);
            });
        }
    }
    openSendMsgInterface(){
        this.setState({
            isOpenSendMsgInterface:true,
            msg:"",
        })
    }
    sendMsg(){
        const _self=this;
        if (!this.state.msg.trim()){
            alert ("write a msg first");
            return;
        }
        db.collection("runners").doc(this.props.runnerId).update({
            messagesReceived:firebase.firestore.FieldValue.arrayUnion({senderId:firebase.auth().currentUser.uid,msg:_self.state.msg.trim()}),
            isShowNewMsgsAlert:true,
        })
        .then(()=>{
            Helper.sendEmail(_self.props.oUser.name,_self.state.msg.trim(),Helper.fetchRunnerById(_self.props.runnerId).email);
            alert ("message was successsfully sent");
            this.setState({
                isOpenSendMsgInterface:false,
            })
        })
        .catch((error)=>{
            console.error("an error in saving IM to db. error is - ",error);
        })
    }
    setMsg(e){
        this.setState({
            msg:e.target.value,
        })
    }
    cancelSendMsg(){
        this.setState({
            isOpenSendMsgInterface:false,
        })
    }
    sendChatRequest(){
        db.collection("runners").doc(this.props.runnerId).get()
        .then((doc)=>{
            switch(doc.data().chatState){
                case "free":
                    chatDbManager.updateChatData("chatRequest",this.props.runnerId,"");
                    break;
                default:
                    alert ("the runner in in a chat right now");
                    break;
            }
        })
        .catch((error)=>{
            console.log("error in sendChatReuqest. err is - "+error);
        })
    }
    back(){
        this.props.setPage(this.props.prevPg);
    }
}
const mapStateToProps = (state)=>{
    return {
        loadStatus:state.runners.action,
        connected:state.runners.connected,
        isConnected:state.upState.isConnected,
        prevPg:state.upState.prevPg,
        oUser:state.upState.user,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        fetchOneRunner:(sId)=>{dispatch(fetchOneRunner(sId))},
        setPage:(sPg)=>dispatch(setPage(sPg)),
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(RunnerPage);