import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {incrementClicks} from "./redux/actions/items";
import {Provider} from "react-redux";
import store from "./redux/store/configureStore";
import App from "./components/App";



class AppWrapper extends Component {
    constructor(props){
        super(props);
        this.state= {
            cliks:0,
        }
        store.subscribe(()=>{
//            store.dispatch(incrementClicks());
                this.setState({
                    cliks:store.getState().items.clicks,
                });
        })
    }
    render(){
        return (
            <Provider store={store}>
                <App/>
            </Provider>
        )
    }
}
ReactDOM.render(<AppWrapper/>, document.getElementById('root'));
