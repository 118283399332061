import React,{Component} from "react";
import {connect} from "react-redux";
import Activities from "./Activities";
import {APPETIZER_PAGE} from "../constants";
import {setPage} from "../redux/actions/state";

class City extends Component {
    constructor(props){
        super(props);
        this.back=this.back.bind(this);
    }
    render(){
        return (
            <div className="cityPg">
                <Activities cityId={this.props.selectedCityId}/>
                <input type="button" className="backBtn" value="back" onClick={this.back}/>
            </div>
        )
    }
    back(){
        this.props.setPage(APPETIZER_PAGE);
    }
}

const mapStateToProps = (state) => {
    return {
        selectedCityId:state.upState.selectedCityId,
        cities:state.cities.cities,
/*
        action:state.cities.action,
        cities:state.cities.cities,
*/        
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setPage:(pg)=>{dispatch(setPage(pg))},
    };
};



export default connect(mapStateToProps,mapDispatchToProps)(City);
