import {NONE,FETCH_LANGUAGES_SUCCESS,FETCH_LANGUAGES_ERROR} from "../../constants.js";
import {db} from "../../components/firebase";

export const fetchLanguagesSuccess = (languages)=>({
    type:FETCH_LANGUAGES_SUCCESS,
    languages,
});

export const fetchLanguagesFailure = (error)=>({
    type:FETCH_LANGUAGES_ERROR,
    error,
});

export const fetchLanguages = ()=>{
    return (dispatch) => {
        db.collection("languages")
        .orderBy("name")
        .get()
        .then(function(querySnapshot) {
            var arLanguages=[];
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                arLanguages.push({id:doc.id,...doc.data()});
            });
            dispatch(fetchLanguagesSuccess(arLanguages));
        })
        .catch(function(error) {
            dispatch(fetchLanguagesFailure(error));
        });
    }
}