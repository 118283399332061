import {NONE,FETCH_ACTIVITIES_BEGIN,FETCH_ACTIVITIES_SUCCESS,FETCH_ACTIVITIES_ERROR, REPLACE_ACTIVITY,ADD_ACTIVITY,FETCH_ONE_ACTIVITY_BEGIN,FETCH_ONE_ACTIVITY_ERROR,FETCH_ONE_ACTIVITY_SUCCESS} from "../../constants.js";
import firebase,{db} from "../../components/firebase";


export const fetchActivitiesBegin = ()=>({
    type:FETCH_ACTIVITIES_BEGIN,
});

export const fetchActivitiesSuccess = (activities)=>({
    type:FETCH_ACTIVITIES_SUCCESS,
    activities,
});
export const fetchActivitiesFailure = (error)=>({
    type:FETCH_ACTIVITIES_ERROR,
    error,
});
export const fetchOneActivityBegin=()=>({
    type:FETCH_ONE_ACTIVITY_BEGIN,
})
export const fetchOneActivitySuccess=(activity)=>({
    type:FETCH_ONE_ACTIVITY_SUCCESS,
    activity,
})
export const fetchOneActivityFailure=(error)=>({
    type:FETCH_ONE_ACTIVITY_ERROR,
    error,
})
export const fetchOneActivity = (activityId)=>{
    return (dispatch) => {
        dispatch(fetchOneActivityBegin());
        db.collection("activities").doc(activityId)
        .get()
        .then(function(doc) {
            if (doc.exists){
                const oActivity={id:doc.id,...doc.data()};
                dispatch(fetchOneActivitySuccess(oActivity));
            }
        })
        .catch(function(error) {
            console.log ("error in fetch activity. err is "+error);
            dispatch(fetchOneActivityFailure(error));
        });
    }
}
export const fetchActivities = (cityId)=>{
    return (dispatch) => {
        dispatch(fetchActivitiesBegin());
        db.collection("activities").where ("city","==",cityId)
        .orderBy("name")
        .get()
        .then(function(querySnapshot) {
            var arActivities=[],ii;
            querySnapshot.docs.forEach(doc => {
                // doc.data() is never undefined for query doc snapshots
                let oDoc={id:doc.id,...doc.data()};
                oDoc.nextActDt=null;
                for (const sActNm in doc.data().oScheduledAct){
                    const fActDt=parseFloat(sActNm),f12Hours=43200000,f8Hours=28800000,f0Hours=0;
                    if ((!oDoc.nextActDt && fActDt>(new Date()).getTime()+f12Hours) || 
                        (oDoc.nextActDt && fActDt>oDoc.nextActDth) || 
                        (firebase.auth().currentUser && oDoc.initiator===firebase.auth().currentUser.uid)){
                            oDoc.nextActDt=fActDt;
                        }
                }
                if (oDoc.nextActDt) arActivities.push(oDoc);
            });
            arActivities.sort((o1,o2)=>o1.nextActDt<o2.nextActDt ? -1 : 1);

            dispatch(fetchActivitiesSuccess(arActivities));
        })
        .catch(function(error) {
            console.log ("error in fetch eactivities. err details are - "+error);
            dispatch(fetchActivitiesFailure(error));
        });
    }
}
export const replaceActivity = (activity)=>({
    type:REPLACE_ACTIVITY,
    activity,
})
export const addActivity = (activity)=>({
    type:ADD_ACTIVITY,
    activity,
})