import React,{Component} from "react";
import {setPage} from "../redux/actions/state.js";
import {connect} from "react-redux";

class About extends Component {
    constructor(props){
        super(props);
        this.back=this.back.bind(this);
    }
    render(){
        return (
            <div id="eAboutWrapper">
                <div>
                    i remember it as if it was just the other day, although five years passed since it happened. sometime i think that since i remember it so vaguely, it might have not happened at all, but then i will be left with no interesting story to tell, so i prefer to put some bright colors on what is blurred and fill the missing gaps with my own imagination.
                </div>
                <div>
                    what i remember is myself, in the midst of a run, after doing about 2km running along the tel-aviv boardwalk, in a rather easy pace (as usual) witnessing a national geographic episode: a japanese tourist was standing at a junction, running in place, his mind trying desperately to grapple with the following enigma: which road to choose now. which road will best serve his running self. one road went left, the other right, but none of them was more persuasive than others. none contained any clues that would help him find the right path.
                </div>
                <div>
                    so he flipped an imaginary coin in his head, and took the right turn. urggggggggggggh. the wrong turn. he will shortly find himself back in the more busy part of the city, two many cars, two many crosswalks, too many red lights. on the other hand, the left turn was a completely different story. taking the left turn would lead him to tel aviv great park - park hayarkon, running on the river bank with all the green, clean air and serenity a person can ask in the middle of a big city. in this moment, or so i continuously tell myself, a seed was planted, and its fruits are ripe now, so i hope.
                </div>
                <div>
                    this site is a matchmaker of sorts. it brings together people who enjoy sporting activities: showing the best running routes in a new city, delivering a training set of exercises and so on. anyone can be a trainer or a partner. and anyone can join in, as long as your planned activity is taking place in one of the cities included in the site (if you want me to add your city, please write to me). many sporting activities are included in the site, but surely i missed some. so, if something that is of interest to you is missing you are more than welcome to write to me about it. 
                </div>
                <div>
                    this site took too long to write, more than two years, while it could have been written in a fraction of that time. but a day job, a family with little children, and last but not least, the corona time we were and are all facing had taken their toll, and made what could have been a nice side project to become somewhat of a life project.
                </div>
                <div>
                    but all of the above are  only excuses. the major obstacle was myself. my self doubts, me feeling that this is not worth the effort, that no one will use it. that if the idea was worth something it would have been already done. those thoughts were responsible for months and months of doing nothing at all.
                </div>
                <div>
                    not that this is over and out. i still think that no one will use it, that the design and user experience are not coherent enough and not professional enough, that is is full of bugs (it is only me on this project, and no serious or coming close to serious debugging was done), that there is no need for what this site has to offer, that it will be empty from the start, and so will remain empty until the day i will decide not to renew the lease with godaddy regarding the domain name. but inspite all the self doubts, i managed to find in myself what was needed to finish this project.
                </div>
                <div>
                    but what made the change, what made this day or these lines before i make the most quiet launch in the history of the internet was the understanding that i have to finish it. in order to continue with my life there is no other option. and so i made a commitment between me and myself to make each day count, to make something that will bring me closer to my target. whether is is code written, or lines of text, or an idea regarding design or ux, or simply a thought about how to make the site better. but to dedicate time in each day so this day will not be lost, even if it sums up to only 5 or 10 minutes work. and this worked just fine.
                </div>
                <div>
                    as i already mentioned the site is far from being complete, so you are probably the real testers. if things don’t work i will be more than happy to hear from you about it. and i want to apologize in advance for the bugs that you will probably face (i hope that not too many).
                </div>
                <div>
                    in any case, i want to share my dream with you. i dream that this site will become really big and will be the nucleus from which a community will thrive. but i will be happy with much less. if someone will be able to have a good sporting experience using this site it will be enough for me. 
                </div>
                <div>
                    for this to happen i want to ask something of you. please don’t use this site in abusive ways. be honest and keep what you promise. since there is no money transfer in this site, there is no real world penalty for not showing up for an activity. but take into account that there is the other side that is committed to the appointment and will probably show up. and also take into account that skipping a scheduled activity will bring this enterprise closer to its end.
                </div>
                <div>
                    since no one will read these lines, after all who opens the about page, i allow myself to be totally ridiculous and dedicate this site to anyone who has a dream. dream on, make each day count, and you will get there. and also to my mother,, brother,  wife and daughters of-course. :)
                </div>
                <div>
                    p.s. my email address is <a href="mailto:shaiz2206@gmail.com">shaiz2206@gmail.com</a>. feel free to write to me on anything.
                </div>
                <input type="button" className="backBtn" value="back" onClick={this.back}/>
            </div>
        )
    }
    back(e){
        e.stopPropagation();
        this.props.setPage(this.props.prevPg);
    }
}
const mapStateToProps = (state) => {
    return {
        prevPg:state.upState.prevPg,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setPage:(sPg)=>dispatch(setPage(sPg)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(About);
