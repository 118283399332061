import {NONE,FETCH_CITY_BEGIN,FETCH_CITY_SUCCESS,FETCH_CITY_ERROR} from "../../constants.js";
import {db} from "../../components/firebase";

export const fetchCitiesBegin = ()=>({
    type:FETCH_CITY_BEGIN,
});

export const fetchCitiesSuccess = (cities)=>({
    type:FETCH_CITY_SUCCESS,
    cities,
});

export const fetchCitiesFailure = (error)=>({
    type:FETCH_CITY_ERROR,
    error,
});

export const fetchCities = ()=>{
    return (dispatch) => {
/*
        return fetch("https://www.google.com/search?q=secret+sauce")
        .then(()=>dispatch(incrementClicks()))
        .catch((err)=>alert (err))
        .finally ((res)=>dispatch(updateShowOff("shuru buru")));
*/
        dispatch(fetchCitiesBegin());

        db.collection("cities")
        .orderBy("city")
        .get()
        .then(function(querySnapshot) {
            var arCities=[];
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                arCities.push({id:doc.id,...doc.data()});
            });
            dispatch(fetchCitiesSuccess(arCities));
        })
        .catch(function(error) {
            dispatch(fetchCitiesFailure(error));
        });
    }
}