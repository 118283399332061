import {APPETIZER_PAGE,SET_PG,SET_CITY_ID,SET_RUNNER_ID,SET_ACTIVITY_ID,CONNECT_USER,DISCONNECT_USER,REPLACE_USER,SET_SCHEDULED_ACTIVITY_ID,SET_USER_NAME} from "../../constants";
import { initializeApp } from "firebase";


const initialState= {
/*    pg:SET_DATES_PAGE,*/
    pg:APPETIZER_PAGE,
    prevPg:APPETIZER_PAGE,
    selectedCityId:"",
    selectedRunnerId:"",
    selectedActivityId:"",
    selectedScheduledActivityId:"",
    isConnected:false,
    userNm:"",
    user:{},
}


const stateReducer = (state=initialState,{type,pg,selectedCityId,selectedRunnerId,userNm,user,selectedActivityId,setPrev,selectedScheduledActivityId})=>{
    switch (type){
        case SET_PG:
            return {
                ...state,
                prevPg:setPrev ? (state.pg!==pg ? state.pg : state.prevPg) : state.prevPg,
                pg
            }
        case SET_CITY_ID:
            return {
                ...state,
                selectedCityId,
            }
        case SET_RUNNER_ID:
            return {
                ...state,
                selectedRunnerId,
            }
        case SET_ACTIVITY_ID:
            return {
                ...state,
                selectedActivityId,
            }
        case SET_SCHEDULED_ACTIVITY_ID:
                return {
                    ...state,
                    selectedScheduledActivityId,
                }
            case CONNECT_USER:
            return {
                ...state,
                isConnected:true,
                userNm,
                user,
            }
        case DISCONNECT_USER:
            return {
                ...state,
                isConnected:false,
                userNm:"",
                user:{},
            }
        case REPLACE_USER:
            return {
                ...state,
                user,
            }
        case SET_USER_NAME:
            return {
                ...state,
                userNm,
            }
        default:
            return state;
    }
}

export default stateReducer;