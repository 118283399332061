
import {NONE,FETCH_AVAILABILITY_SUCCESS,FETCH_AVAILABILITY_ERROR} from "../../constants.js";
const initialState = {
    action:NONE,
    location:[],
}


const availabilityReducer = (state=initialState,{type,availability})=>{
    switch (type){
        case FETCH_AVAILABILITY_SUCCESS:
            return {
                ...state,
                action:type,
                availability,
            }
        case FETCH_AVAILABILITY_ERROR:
            return {
                ...state,
                action:type,
            }
        default:
            return state;
    }
}

export default availabilityReducer;