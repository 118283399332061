import React,{Component} from "react";
import {connect} from "react-redux";
import {setPage,setUserNm} from "../redux/actions/state";
import firebase,{db} from "./firebase";
import {startTracking} from "../helper";
import {setChatterId,setChatText} from "../redux/actions/chat";

class Signup extends Component {
    constructor(props){
        super(props);
        this.state={
            username:"",
            email:"",
            password:"",
            passwordAgain:"",
        };
        this.signup=this.signup.bind(this);
        this.setUsername=this.setUsername.bind(this);
        this.setEmail=this.setEmail.bind(this);
        this.setPassword=this.setPassword.bind(this);
        this.setPasswordAgain=this.setPasswordAgain.bind(this);
        this.closePopup=this.closePopup.bind(this);
    }
    render(){
        return (
            <div id="eSignupPg">
                <div id="eSignupPopup">
                    <div id="eClosePopupBtn" onClick={this.closePopup}></div>
                    <input id="eUsername" className="itm inputFld" type="text" placeholder="username" autoFocus value={this.state.username} onChange={this.setUsername}/>
                    <input id="eEmail" className="itm inputFld" type="text" placeholder="email" value={this.state.email} onChange={this.setEmail}/>
                    <input id="ePassword" className="itm inputFld" type="password" placeholder="password" value={this.state.password} onChange={this.setPassword}/>
                    <input id="ePasswordAgain" className="itm inputFld" type="password" placeholder="retype password" value={this.state.passwordAgain} onChange={this.setPasswordAgain}/>
                    <button id="eSignupBtn" className="itm btn" onKeyPress={(e)=>{if (e.keyCode===13)this.signup();}} onClick={this.signup}>Sign up</button>
                </div>
            </div>
        )
    }
    componentDidMount(){
        startTracking(this);
    }
    validate(){
        var bValid=true;
        const sUserNm=this.state.username.trim(),sPwd=this.state.password.trim(),sEmail=this.state.email.trim(),
            sPwdAgain=this.state.passwordAgain.trim();
        
        if (sEmail.trim()===""){
            alert ("the email fld is empty");
            document.querySelector("#eSignupPg #eEmail").focus();
            bValid=false;
        }
        else if (sUserNm.trim()===""){
            alert ("the user name fld is empty");
            document.querySelector("#eSignupPg #eUsername").focus();
            bValid=false;
        }
        else if (sPwd.trim()===""){
            alert ("the password fld is empty");
            document.querySelector("#eSignupPg #ePassword").focus();
            bValid=false;
        }
        else if (sPwd.trim()!==sPwdAgain.trim()){
            alert ("the password in the original and verification flds are different");
            document.querySelector("#eSignupPg #ePassword").focus();
            bValid=false;
        }
        return bValid;
    }
    signup(){
        const sUserNm=this.state.username.trim(),sPwd=this.state.password.trim(),sEmail=this.state.email.trim();
        const self=this;    
        if (!this.validate())return;
        firebase.auth().createUserWithEmailAndPassword(sEmail/*+"12345678@gmail.com"*/,sPwd)
        .then(function(result){
            self.props.setUserNm(sUserNm);
            result.user.updateProfile({
                displayName:sUserNm,
            })
            .then(function(){
                window.setTimeout(function(){
                    result.user.sendEmailVerification()
                    .then(function(){
                    })
                    .catch(function(oErr){
                    });
                },100)
            });
        })
        .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            alert (errorMessage);
            // ...
        });
    }
    setUsername(e){
        this.setState({
            username:e.target.value,
        })
    }
    setEmail(e){
        this.setState({
            email:e.target.value,
        })
    }
    setPassword(e){
        this.setState({
            password:e.target.value,
        })
    }
    setPasswordAgain(e){
        this.setState({
            passwordAgain:e.target.value,
        })
    }
    closePopup(){
        this.props.setPage(this.props.prevPg);
    }
}
const mapStateToProps = (state)=>{
    return {
        prevPg:state.upState.prevPg,
        pg:state.upState.pg,
        userNm:state.upState.userNm,
    }
}
const mapDispatchToProps = (dispatch)=>{
    return {
        setPage:(sPg)=>{dispatch(setPage(sPg))},
        setChatterId:(sChatterId)=>{dispatch(setChatterId(sChatterId))},
        setChatText:(sSide,sChatText)=>{dispatch(setChatText(sSide,sChatText))},
        setUserNm:(sNm)=>{dispatch(setUserNm(sNm))},
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Signup);