import firebase,{db} from "./components/firebase";
import { firestore } from "firebase";


class chatDbManager {
    constructor(){

    }
    static updateChatData(sChatState,sRunnerId,sChatTxt){
        db.collection("runners").doc(sRunnerId).set({
            chatState:sChatState,
            chatterId:firebase.auth().currentUser.uid,
            chatTxt:sChatTxt
        },{merge:true})
        .then(()=>{

        })
        .catch((error)=>{
            console.error("software failure in updateChatData function in chatDbManager class. error is -",error);
        });
    }
}

export default chatDbManager;