import React,{Component} from "react";
import {ABOUT_PAGE} from "../constants";
import {connect} from "react-redux";
import {setPage} from "../redux/actions/state.js";
class Footer extends Component{
    constructor(props){
        super(props);
        this.openAboutPg=this.openAboutPg.bind(this);
    }
    render(){
        return( 
            <footer>
                <div className="itm" onClick={this.openAboutPg}>About</div>
                <div className="itm">Contact us</div>
                <div className="itm">Jobs</div>
            </footer>
        );
    }
    openAboutPg(){
        this.props.setPage(ABOUT_PAGE);
    }
}
const mapStateToProps = (state) => {
    return {
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setPage:(sPg)=>dispatch(setPage(sPg)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(Footer);
