import React,{Component} from "react";
import {connect} from "react-redux";
import Hdr from "./Hdr";
import InputFld from "./InputFld";
import {setPage,setSelectedCityId} from "../redux/actions/state.js";
import {NONE,FETCH_CITY_BEGIN,FETCH_CITY_SUCCESS,FETCH_CITY_ERROR,CITY_PAGE} from "../constants.js";

class Appetizer extends Component {
    constructor(props){
        super();
        this.state = {
            loaded:false,
            selectedCityId:"",
        }
        this.chngCityVid=this.chngCityVid.bind(this);
        this.getSelectedCityByStateId=this.getSelectedCityByStateId.bind(this);
        this.enterCity = this.enterCity.bind(this);
    }
    render(){
        var elmToReturn=<div></div>;
        if (this.props.action===NONE){
            elmToReturn=<div>nothing to show yet</div>;
        }
        else if (this.props.action===FETCH_CITY_BEGIN){
            elmToReturn=<div>starting to load data</div>;
        }
        else if (this.props.action===FETCH_CITY_SUCCESS){
            var eOptions=this.props.cities.map((oData,indx)=><option value={oData.id} key={indx}>{oData.city+" - "+oData.country}</option>);
                   elmToReturn=<div id="eAppetizer">
                <Hdr title="Running Vs City"/>
                <Hdr title="Finding a partner or trainer for your sporting activity was never so easy" secondaryClass="subHdr topMe0"/>
                <InputFld>
                    <select id="eCitySelector" onChange={this.chngCityVid} value={this.state.selectedCityId}>{eOptions}</select>
                </InputFld>
                <InputFld>
                    <div id="eEnterBtn" className="btn" onClick={this.enterCity}>Enter</div>
                </InputFld>
            </div>;
        }
        else{
            elmToReturn=<div>something happened. what the fuccck ?</div>;
        }        
        return elmToReturn;
    }
    chngCityVid(event){
        this.setState({
            loaded:true,
            selectedCityId:event.target.value,
        })
    }
    componentDidMount(){
        try{
            if (!this.state.loaded){
/*
                var iSelected=Math.round(Math.random()*(this.props.cities.length-1));
                if (document.getElementById("eCitySelector")){
                    this.setState({loaded:true,
                        selectedCityId:this.props.cities[iSelected].id,
                    })
                }
*/                
                let iSelected=0;
                this.props.cities.forEach(function(obj,ii){
                    if (obj.city==="tel-aviv"){
                        iSelected=obj.id;
                        return false;
                    }
                });
                this.setState({loaded:true,
                    selectedCityId:iSelected,
                })
            }   
        }
        catch(err){
            console.log ("there is a problem with the internet connection. maybe you're not connected ?");
        }
    }
    getSelectedCityByStateId(){
        var oCity=null,self=this;
        this.props.cities.forEach(function(obj,ii){
            if (obj.id===self.state.selectedCityId){
                oCity=obj;
                return false;
            }
        });
        return oCity;
    }
    enterCity(){
        this.props.setPage(CITY_PAGE);
        this.props.setSelectedCityId(this.state.selectedCityId);
    }
}

const mapStateToProps = (state) => {
    return {
        action:state.cities.action,
        cities:state.cities.cities,
        pg:state.upState.pg,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setPage:(sPg)=>dispatch(setPage(sPg)),
        setSelectedCityId:(sId)=>dispatch(setSelectedCityId(sId)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(Appetizer);

