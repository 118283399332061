    import React,{Component} from "react";
    import {connect} from "react-redux";
    import {setPage,disconnectUser,setSelectedRunnerId,setSelectedActivityId,setSelectedScheduledActivityId} from "../redux/actions/state";
    import {fetchOneRunner,setNumOfRunnersToRetrieve} from "../redux/actions/runners";
    import {LOGIN_PAGE,SIGNUP_PAGE,PROFILE_PAGE,RUNNER_PAGE,APPETIZER_PAGE,ACTIVITY_PAGE,ACTIVITY_MAKER_PAGE,SET_DATES_PAGE,CHOOSE_DATES_PAGE} from "../constants";
    import firebase,{db} from "./firebase";

    class OpenSesamiInterface extends Component {
        constructor(props){
            super(props);
            this.login=this.login.bind(this);
            this.signup=this.signup.bind(this);
            this.signOut=this.signOut.bind(this);
            this.addActivity=this.addActivity.bind(this);
            this.manageProfile=this.manageProfile.bind(this);
            this.toggleMsgs=this.toggleMsgs.bind(this);
            this.hideMsgs=this.hideMsgs.bind(this);
            this.toggleDashboardContextMenuState=this.toggleDashboardContextMenuState.bind(this);
            this.setDashboardContextMenuState=this.setDashboardContextMenuState.bind(this);
            this.getRunnerById=this.getRunnerById.bind(this);
            this.openAppetizerPage=this.openAppetizerPage.bind(this);
            this.hideDashboardContextMenu=this.hideDashboardContextMenu.bind(this);
            this.delMsg=this.delMsg.bind(this);
            this.opennActivity=this.openActivity.bind(this);

            this.state={
                isShowMsgs:false,
                isShowContextMenu:false,
                isShowNewMsgsAlert:true,
                arMsgs:null,
            }
        }
        render(){
            return (
                <header className="openSesamiInterface">
                    <div id="eDashboard">
                        <div id="eLogoInDashboard" onClick={this.openAppetizerPage}><span id="eLogoImg"></span>Running Vs City</div>
                        {this.props.isConnected && <div id="eDashboardBtns">
                            <div id="eMessagesBtnInDashboard" onClick={this.toggleMsgs}>
                                <div id="eMessagesIconInDashboard" className="itm"></div>
                                {this.state.isShowNewMsgsAlert && this.props.user.isShowNewMsgsAlert && <div id="eNewMessagesAlert"></div>}
                            </div>
                            <button id="eActionBtnInDashboard" className="itm" onClick={this.toggleDashboardContextMenuState}>
                                <div id="eUserNameInActionBtnInDashboard">hi, {this.props.userNm}</div>
                                <div id="eUserIconeUserNameInActionBtnInDashboard"></div>
                            </button>
                            {this.state.isShowMsgs && this.state.arMsgs /*&& this.props.numOfRunnersToRetrieve===0*/ && this.props.user.messagesReceived && this.props.user.messagesReceived.length>0 && <div id="eMessagesInDashboard">
                                {this.state.arMsgs.map((oMsg,ii)=>{
                                    return this.getRunnerById(oMsg.senderId) && <div className="itm" key={ii}><div className="sender" title={this.getRunnerById(oMsg.senderId).name} onClick={()=>{this.openRunnerPg(oMsg.senderId)}}></div><div className="msg">{oMsg.msg}{(oMsg.msgType==="reg_request" || oMsg.msgType==="reg_accepted") && <span className="btnAsLink nowrap leftMe1" onClick={()=>this.openActivity(oMsg.senderId,oMsg.activityId,oMsg.scheduledActId,oMsg.msgType)}>open diary</span>}</div><button data-position={ii} className="delMsgBtn" onClick={this.delMsg}></button></div>
                                })}
                            </div>
                            }
                            {this.state.isShowContextMenu && 
                            <div id="eDashboardContextMenu">
                                <div className="itm" onClick={this.addActivity}>Add Activity</div>
                                <div className="itm" onClick={this.manageProfile}>Profile</div>
                                <div className="itm" onClick={()=>this.signOut(true)}>Log out</div>
                            </div>
                            }
                        </div>}
                        {!this.props.isConnected && <div id="eLoginSignupBtns"><div id="eLoginBtnInHdr" className="btn" onClick={this.login}>Log in</div><div id="eSignupBtnInHdr" className="btn" onClick={this.signup}>Sign up</div></div>}
                    </div>
                </header>
            )
        }
        login(){
            this.signOut();
            this.props.setPage(LOGIN_PAGE);
        }
        signup(){
            this.signOut();
            this.props.setPage(SIGNUP_PAGE);
        }
        signOut(bReturnToMain){
            const self=this;
            this.setDashboardContextMenuState(0);
            firebase.auth().signOut()
            .then(function(){
                self.props.disconnectUser();
                self.hideMsgs();
                if (!!bReturnToMain)self.props.setPage(APPETIZER_PAGE);
            })
            .catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log (errorMessage);
                // ...
            });        
        }
        addActivity(){
            this.props.setSelectedActivityId("");
            this.setDashboardContextMenuState(0);
            this.props.setPage(ACTIVITY_MAKER_PAGE);
        }
        manageProfile(){
            this.setDashboardContextMenuState(0);
            this.props.setPage(PROFILE_PAGE);
        }
        toggleMsgs(){
            this.hideDashboardContextMenu();
            if (!this.state.isShowMsgs){
                if (this.state.isShowNewMsgsAlert){
                    db.collection("runners").doc(firebase.auth().currentUser.uid).update({
                        isShowNewMsgsAlert:false,
                    })
                }
                this.props.setNumOfRunnersToRetrieve(-1);
                db.collection("runners").doc(firebase.auth().currentUser.uid).get()
                .then((doc)=>{
                    if (doc.exists){
                        const arMsgs=doc.data().messagesReceived ? doc.data().messagesReceived : [];
                        let arIds=[];
                        arMsgs.forEach((oMsg)=>{
                            if (!arIds.includes(oMsg.senderId))arIds.push(oMsg.senderId);
                        })
                        this.props.setNumOfRunnersToRetrieve(arIds.length);
                        arIds.forEach((sId,iIndx)=>{
                            this.props.fetchOneRunner(sId,true);
                        })
                        this.setState({
                            arMsgs,
                            isShowNewMsgsAlert:false,
                        });
                    }
                })
                .catch((err)=>{
                    const sErr=err.message;
                    console.error ("err in toggleMsgs() when trying to retrieve immediate msgs. err is - "+sErr);
                })
            }
            this.setState((prevState,props)=>({
                isShowMsgs:!prevState.isShowMsgs,
            }));
        }
        hideMsgs(){
            this.setState({
                isShowMsgs:false,
            })
        }
        hideDashboardContextMenu(){
            this.setState({
                isShowContextMenu:false,
            })            
        }
        toggleDashboardContextMenuState(){
            this.hideMsgs();
            this.setState((prevState,props)=>({
                isShowContextMenu:!prevState.isShowContextMenu,
            }));
        }
        setDashboardContextMenuState(bState){
            this.setState({
                isShowContextMenu:!!bState,
            })
        }
        getRunnerById(sId){
            let oRunner=null;
            this.props.runners.forEach((oRunnerInRunners)=>{
                if (oRunnerInRunners.id===sId){
                    oRunner=oRunnerInRunners;
                    return false;
                }
            });
            return oRunner;
        }
        openRunnerPg(sId){
            this.toggleMsgs();
            this.props.setSelectedRunnerId(sId);
            this.props.setPage(RUNNER_PAGE);
        }
        openAppetizerPage(){
            this.props.setPage(APPETIZER_PAGE);
        }
        delMsg(e){
            const iPosition=parseInt(e.target.getAttribute("data-position"),10);
            const arMsgs=this.state.arMsgs.filter((oMsg,ii)=>ii!==iPosition);
            this.setState((prevState,props)=>({
                arMsgs,
            }));
            db.collection("runners").doc(firebase.auth().currentUser.uid)
            .update({messagesReceived:arMsgs})
            .then(()=>{
            })
            .catch((err)=>{
                console.error("an error in delMsg.err is - "+err.message);
            });
        }
        openActivity(sSenderId,sActivityId,sSchedulledActivityId,sMsgType){
            this.props.setSelectedActivityId(sActivityId);
            this.props.setSelectedScheduledActivityId(sSchedulledActivityId);
            if (sMsgType==="reg_request"){
                this.props.setPage(SET_DATES_PAGE,ACTIVITY_MAKER_PAGE);
            }
            else if (sMsgType==="reg_accepted"){
                this.props.setPage(CHOOSE_DATES_PAGE,ACTIVITY_PAGE);
            }
            this.setState({
                isShowMsgs:false,
            })
        }
    }
    const mapStateToProps = (state)=>{
        return {
            selectedPg:state.upState.pg,
            isConnected:state.upState.isConnected,
            userNm:state.upState.userNm,
            user:state.upState.user,
            numOfRunnersToRetrieve:state.runners.numOfRunnersToRetrieve,
            runners:state.runners.runners,
        }
    }
    const mapDispatchToProps = (dispatch)=>{
        return {
            setPage:(sPg)=>{dispatch(setPage(sPg))},
            setSelectedRunnerId:(sId)=>{dispatch(setSelectedRunnerId(sId))},
            setSelectedActivityId:(sId)=>{dispatch(setSelectedActivityId(sId))},
            setSelectedScheduledActivityId:(sId)=>{dispatch(setSelectedScheduledActivityId(sId))},
            disconnectUser:()=>{dispatch(disconnectUser())},
            fetchOneRunner:(sRunnerId,bDecrease)=>{dispatch(fetchOneRunner(sRunnerId,bDecrease))},
            setNumOfRunnersToRetrieve:(iNum)=>{dispatch(setNumOfRunnersToRetrieve(iNum))},
        }
    }

    export default connect(mapStateToProps,mapDispatchToProps)(OpenSesamiInterface);