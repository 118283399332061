import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class ImgGallery extends React.Component{
    render(){
        const eImages=this.props.arImages.map((oImg,iIndx)=>{
            return (
                <div key={iIndx}>
                    <img src={oImg.original}/>
                </div>
            );
        });

        return (
            <div id="eActivityGalleryWrapper">
                <Carousel showThumbs={false}>
                    {eImages}
                </Carousel>
            </div>
        )
    }
}

export default ImgGallery;