
import {SET_PG,SET_CITY_ID,SET_RUNNER_ID,SET_ACTIVITY_ID,CONNECT_USER,DISCONNECT_USER,REPLACE_USER,SET_SCHEDULED_ACTIVITY_ID,SET_USER_NAME} from "../../constants.js";

export const setPage = (sPg,bSetPrev=true) =>({
    type:SET_PG,
    pg:sPg,
    setPrev:bSetPrev,
})

export const setUserNm = (sNm) =>({
    type:SET_USER_NAME,
    userNm:sNm,
})

export const setSelectedCityId = (sId)=>({
    type:SET_CITY_ID,
    selectedCityId:sId,

})

export const setSelectedRunnerId = (sId)=>({
    type:SET_RUNNER_ID,
    selectedRunnerId:sId,

})

export const setSelectedActivityId = (sId)=>({
    type:SET_ACTIVITY_ID,
    selectedActivityId:sId,

})

export const setSelectedScheduledActivityId = (sId)=>({
    type:SET_SCHEDULED_ACTIVITY_ID,
    selectedScheduledActivityId:sId,
})

export const connectUser=(sUserNm,user)=>({
    type:CONNECT_USER,
    userNm:sUserNm,
    user,
})

export const disconnectUser=()=>({
    type:DISCONNECT_USER,
})

export const replaceUser=(user)=>({
    type:REPLACE_USER,
    user,
})

