import {NONE,FETCH_RUNNERS_BEGIN,FETCH_RUNNERS_SUCCESS,FETCH_ONE_RUNNER_BEGIN,FETCH_ONE_RUNNER_ERROR,FETCH_ONE_RUNNER_SUCCESS,ADD_CONNECTED,REMOVE_CONNECTED,SET_NUM_OF_RUNNERS_TO_RETRIEVE,DECREASE_ONE_FROM_NUM_OF_RUNNERS_TO_RETRIEVE,REPLACE_RUNNER} from "../../constants.js";
const initialState = {
    action:NONE,
    runners:[],
    connected:[],
    numOfRunnersToRetrieve:0,   
}

const runnersReducer = (state=initialState,{type,runners,connectedId,runner,numOfRunnersToRetrieve})=>{
    let arConnected=null,arRunners=null,iNumOfRunnersToRetrieve;
    switch (type){
        case FETCH_RUNNERS_BEGIN:
        case FETCH_ONE_RUNNER_BEGIN:
        case FETCH_ONE_RUNNER_ERROR:
            return {
                ...state,
                action:type,
            }
        case FETCH_RUNNERS_SUCCESS:
            arRunners=state.runners.slice();
            runners.forEach((runner)=>{
                if (arRunners.filter((oRunner)=>oRunner.id===runner.id).length===0)arRunners.push(runner);
            });
            return {
                ...state,
                action:type,
                runners:arRunners,
            }
        case FETCH_ONE_RUNNER_SUCCESS:
            arRunners=state.runners.slice();
            if (runner && arRunners.filter((oRunner)=>oRunner.id===runner.id).length===0)arRunners.push(runner);
            return {
                ...state,
                action:type,
                runners:arRunners
            }
        case ADD_CONNECTED:
            arConnected=state.connected.slice();
            arConnected.push(connectedId);
            return {
                ...state,
                connected:arConnected,
            }
        case REMOVE_CONNECTED:
            arConnected=state.connected.slice();
            arConnected.splice(state.connected.indexOf(connectedId),1);
            return {
                    ...state,
                    connected:arConnected,
                }
        case SET_NUM_OF_RUNNERS_TO_RETRIEVE:
            return {
                ...state,
                numOfRunnersToRetrieve
            }
        case DECREASE_ONE_FROM_NUM_OF_RUNNERS_TO_RETRIEVE:
            iNumOfRunnersToRetrieve=state.numOfRunnersToRetrieve-1;
            return {
                ...state,
                numOfRunnersToRetrieve:iNumOfRunnersToRetrieve,
            }
        case REPLACE_RUNNER:
            arRunners=state.runners.slice();
            arRunners.forEach((oRunner,iIndex)=>{
                if (oRunner.id===runner.id){
                    arRunners.splice(iIndex,1,runner);
                };
            });
            return{
                ...state,
                action:type,
                runners:arRunners,
            };
        default:
            return state;
    }
}

export default runnersReducer;