import {SET_CHAT_TEXT,SET_CHATTER_ID,CLEAR_CHAT} from "../../constants.js";

export const setChatText = (sSide,sChatText)=>({
    type:SET_CHAT_TEXT,
    side:sSide,
    chatText:sChatText,
})

export const setChatterId = (sChatterId) =>({
    type:SET_CHATTER_ID,
    chatterId:sChatterId,
})

export const clearChat = () =>({
    type:CLEAR_CHAT,
})
