import {NONE,FETCH_LANGUAGEMASTERY_SUCCESS,FETCH_LANGUAGEMASTERY_ERROR} from "../../constants.js";
const initialState = {
    action:NONE,
    location:[],
}


const languageMasteryReducer = (state=initialState,{type,languageMastery})=>{
    switch (type){
        case FETCH_LANGUAGEMASTERY_SUCCESS:
            return {
                ...state,
                action:type,
                languageMastery,
            }
        case FETCH_LANGUAGEMASTERY_ERROR:
            return {
                ...state,
                action:type,
            }
        default:
            return state;
    }
}

export default languageMasteryReducer;