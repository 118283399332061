import React,{Component} from "react";
import {connect} from "react-redux";
import firebase,{db} from "./firebase";
import chatDbManager from "../chatDbManager";
import {setPage} from "../redux/actions/state";
import {SET_CHAT_TEXT,FETCH_ONE_RUNNER_SUCCESS} from "../constants.js";
import {setChatText,clearChat} from "../redux/actions/chat";
import {fetchOneRunner} from "../redux/actions/runners";
import Helper from "../helper";

class Chat extends Component {
    constructor(props){
        super(props);
        this.state = {
            sInput:""
        }
        this.sendMsg=this.sendMsg.bind(this);
        this.endChat=this.endChat.bind(this);
        this.setInput=this.setInput.bind(this);
    }
    render(){
        return (
            <div id="eChatWrapper">
                <div id="eChatTextWrapper">{
                    this.props.chat.map((oTake,ii)=><div key={ii} className={oTake.side==="me" ? "mySideOfTheChat" : "yourSideOfTheChat"}><span className="boldMe">{oTake.side=="me" ? "me" :  (this.props.fetchOneRunnerState===FETCH_ONE_RUNNER_SUCCESS && Helper.fetchRunnerById(this.props.chatterId).name ? Helper.fetchRunnerById(this.props.chatterId).name : "you")}:&nbsp;</span>{oTake.chatText}</div>
                    )
                }
                </div>
                <textarea id="eChatInput" onChange={this.setInput} value={this.state.sInput}></textarea>
                <div className="btnsWrapper bottomMe1">
                    <button className="btnAsLink" onClick={this.sendMsg}>send msg</button>
                    <button className="btnAsLink" onClick={this.endChat}>end chat</button>
                </div>
            </div>
        )
    }
    componentDidMount(){
        this.clearInputFld();
        this.props.clearChat();
        this.props.fetchOneRunner(this.props.chatterId);
    }
    componentDidUpdate(){
        document.getElementById("eChatTextWrapper").scrollTo(0,document.getElementById("eChatTextWrapper").scrollHeight);
    }
    clearInputFld(){
        this.setState({
            sInput:"",
        });
        document.getElementById("eChatInput").select();

    }
    sendMsg(){
        this.props.setChatText("me",this.state.sInput);
        chatDbManager.updateChatData("chat",this.props.chatterId,this.state.sInput);
        this.clearInputFld();
    }   
    setInput(e){
        this.setState({
            sInput:e.target.value,
        })
    }
    endChat(){
        alert ("you chose to end the chat");
        chatDbManager.updateChatData("endChat",this.props.chatterId,"");
        chatDbManager.updateChatData("free",firebase.auth().currentUser.uid,"");
        this.props.setPage(this.props.prevPg);
    }

}

const mapStateToProps = (state)=>{
    return {
        chatterId:state.chat.chatterId,
        chat:state.chat.chat,
        prevPg:state.upState.prevPg,
        fetchOneRunnerState:state.runners.action,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        setChatText:(sSide,sTxt)=>{dispatch(setChatText(sSide,sTxt))},
        setPage:(sPg)=>{dispatch(setPage(sPg))},
        fetchOneRunner:(runnerId)=>{dispatch(fetchOneRunner(runnerId))},
        clearChat:()=>{dispatch(clearChat())},
    }
}

export default connect (mapStateToProps,mapDispatchToProps)(Chat);
