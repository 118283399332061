import {NONE,FETCH_ACTIVITYSCOPE_SUCCESS,FETCH_ACTIVITYSCOPE_ERROR} from "../../constants.js";
import {db} from "../../components/firebase";

export const fetchActivityScopeSuccess = (activityScope)=>({
    type:FETCH_ACTIVITYSCOPE_SUCCESS,
    activityScope,
});

export const fetchActivityScopeFailure = (error)=>({
    type:FETCH_ACTIVITYSCOPE_ERROR,
    error,
});

export const fetchActivityScope = ()=>{
    return (dispatch) => {
        db.collection("activityScope")
        .orderBy("name")
        .get()
        .then(function(querySnapshot) {
            var arActivityScope=[];
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                arActivityScope.push({id:doc.id,...doc.data()});
            });
            dispatch(fetchActivityScopeSuccess(arActivityScope));
        })
        .catch(function(error) {
            dispatch(fetchActivityScopeFailure(error));
        });
    }
}