import {ACTIVITY_PAGE,ACTIVITY_MAKER_PAGE,CHOOSE_DATES_PAGE,
        RUNNER_PAGE} from "../constants.js";
import React,{Component} from "react";
import {connect} from "react-redux";
import {setPage,setSelectedActivityId,setSelectedRunnerId} from "../redux/actions/state";
import {fetchOneRunner} from "../redux/actions/runners";
import firebase,{db} from "./firebase";
import chatDbManager from "../chatDbManager";
import ImgGallery from "./ImageGallery";
import Helper from "../helper";
import NodeMailer from "nodemailer";
import moment from "moment";

class ActivityCard extends Component{
    constructor(props){
        super(props);
        this.openActivityPg=this.openActivityPg.bind(this);
        this.openSendMsgInterface=this.openSendMsgInterface.bind(this);
        this.sendMsg=this.sendMsg.bind(this);
        this.setMsg=this.setMsg.bind(this);
        this.sendChatRequest=this.sendChatRequest.bind(this);
//        this.chkIfUpdate=this.chkIfUpdate.bind(this);
        this.fetchLanguages=this.fetchLanguages.bind(this);
        this.back=this.back.bind(this);
        this.downloadImg=this.downloadImg.bind(this);
        this.editActivity=this.editActivity.bind(this);
        this.cancelSendMsg=this.cancelSendMsg.bind(this);
        this.chooseDates=this.chooseDates.bind(this);
        this.sendComment=this.sendComment.bind(this);
        this.openCommentMakerCard=this.openCommentMakerCard.bind(this);
        this.state = {
            isOpenSendMsgInterface:false,
            msg:"",
            mainImg:"",
            arImages:[],
            isHostVid:false,
            hostVidSrc:"",
        }
    }
    render(){
        let sClassName="activityCard";
        if (this.props.isShowAllData)sClassName="activityPage";
        if (this.state.activity){
            return (
                <div className={sClassName}>
                    {this.props.isShowAllData && this.state.activity.arActivityVisualItems.length>0 && <ImgGallery arImages={this.state.arImages}/>}
                    {!this.props.isShowAllData && <div className="visual"><img src={this.state.mainImg ? this.state.mainImg : "https://firebasestorage.googleapis.com/v0/b/runningvscity.appspot.com/o/runnerAvatar.png?alt=media&token=a45a2316-6b1b-45c7-b935-a9a82d1984bd"} className="activityMainImg"/></div>}
                    <div className="cluster topMe0"><span className="itm activityType">{Helper.fetchActivityTypeById(this.state.activity.activityType)}</span><span className="delimiter">-</span><span className="itm activityCity">{this.state.cityName}</span></div>
                    <div className="cluster"><span className="itm activityName">{this.state.activity.name}</span></div>
                        <div className="cluster">
                            {Helper.isActivityTypeContainDistanceAndPaceDataById(this.state.activity.activityType) && <span className="itm">{this.state.activity.distance}&nbsp;km</span>}
                            {Helper.isActivityTypeContainDistanceAndPaceDataById(this.state.activity.activityType) && <span className="delimiter">-</span>}
                            <span className="itm">{this.state.activity.time}&nbsp;hr (total activity duration)</span>
                            {Helper.isActivityTypeContainDistanceAndPaceDataById(this.state.activity.activityType) && <span className="delimiter">-</span>}
                            {Helper.isActivityTypeContainDistanceAndPaceDataById(this.state.activity.activityType) && <span className="itm">{this.state.activity["minutes per km"]}&nbsp;<span title="minutes per km">mins per km</span></span>}
                        </div>
                    <div className="cluster"><span className="itm">{!!this.state.activity.price && parseInt(this.state.activity.price,10)>0 ? this.state.activity.price+" "+Helper.fetchCityById(this.state.activity.city).coin : "FREE"}</span></div>
                    {this.props.isShowAllData && <div className="cluster"><span className="itm">up to {this.state.activity.maxNumOfParticipants} participants</span></div>}
                    {this.state.activity.nextActDt && <div className="cluster"><span className="itm">{moment(this.state.activity.nextActDt).fromNow()}</span></div>}
                    {this.props.isShowAllData && <div className="sectionDetails">
                        <div className="mainCaption">about the activity</div>
                        <div className="subCaption">what we are going to do</div>
                        <div className="details">{this.state.activity.what}</div>
                        <div className="subCaption">where we are going to do it</div>
                        <div className="details">{this.state.activity.where}</div>
                    </div>}
                    {this.props.isShowAllData && !!Helper.fetchRunnerById(this.state.activity.initiator) && <div className="sectionDetails">
                        <div className="mainCaption">about your host</div>
                        <div className="subCaption">general details</div>
                        <div className="splitTwo">
                            <div>
                                <div className="cluster topMe1"><span className="itm">{Helper.fetchRunnerById(this.state.activity.initiator).name}</span><span className="delimiter">-</span><span className="itm">{Helper.fetchRunnerById(this.state.activity.initiator).gender}</span></div>
                                <div className="cluster"><span className="itm"><span className="boldMe">10 km avg:</span>&nbsp;{Helper.fetchRunnerById(this.state.activity.initiator)["10 km avg"]}&nbsp;hrs</span><span className="delimiter">-</span><span className="itm"><span className="boldMe">20 km avg:</span>&nbsp;{Helper.fetchRunnerById(this.state.activity.initiator)["20 km avg"]}&nbsp;hrs</span></div>
                                <div className="cluster">
                                    {
                                        Helper.fetchRunnerById(this.state.activity.initiator).languages && Helper.fetchRunnerById(this.state.activity.initiator).languages.map((oLanguage,iIndex)=>{
                                            let eDelimiter=<span></span>;
                                            if (iIndex+1<Helper.fetchRunnerById(this.state.activity.initiator).languages.length)eDelimiter=<span className="delimiter">-</span>;
                                            return (<span key={iIndex}><span>{Helper.fetchLanguageMasteryById(oLanguage.mastery)}</span>&nbsp;<span className="boldMe">{Helper.fetchLanguageById(oLanguage.languageId)}</span>{eDelimiter}</span>);
                                        })
                                    }
                                </div>
                            </div>
                            <img src={this.state.initiatorMainImg ? this.state.initiatorMainImg : "https://firebasestorage.googleapis.com/v0/b/runningvscity.appspot.com/o/runnerAvatar.png?alt=media&token=a45a2316-6b1b-45c7-b935-a9a82d1984bd"} className="initiatorMainImg"/>
                        </div>
                        <div className="subCaption">in depth</div>
                        <div className="details">{Helper.fetchRunnerById(this.state.activity.initiator)["about myself"]}</div>
                        {this.state.isHostVid && <div>
                            <div className="subCaption bottomMe1">why do i like running ? or anything else about myself</div>
                            <video id="eVideoRecorderWrapper" className="input" controls src={this.state.hostVidSrc} playsinline />
                        </div>}
                        <div className="mainCaption">point of departure</div>
                        <div className="details bottomMe0">{this.state.activity.location.address}</div>
                        <div className="details">{this.state.activity.location.details}</div>
                    </div>}
                    {!this.props.isShowAllData && <button className="btnAsLink rightMe1" onClick={this.openActivityPg}>more details</button>}
                    {this.props.isShowAllData && <input type="button" className="backBtn rightMe1" value="back" onClick={this.back}/>}
                    {!this.props.isShowAllData && this.props.isConnected &&  this.state.activity.initiator===firebase.auth().currentUser.uid && <button className="btnAsLink rightMe1 editActivityBtn" onClick={this.editActivity}>edit activity</button>}
                    {this.props.isConnected && this.state.activity.initiator!==firebase.auth().currentUser.uid && <button className="btnAsLink rightMe1 sendIMBtn" onClick={this.openSendMsgInterface}>send IM</button>}
                    {this.props.isConnected && !!Helper.fetchRunnerById(this.state.activity.initiator) && this.props.connected.includes(this.state.activity.initiator) && this.state.activity.initiator!==firebase.auth().currentUser.uid && Helper.fetchRunnerById(this.state.activity.initiator).chatState==="free" && <button className="btnAsLink rightMe1" onClick={this.sendChatRequest}>chat me up</button>}
                    {this.state.isOpenSendMsgInterface && <div>
                        <textarea className="topMe1" value={this.state.msg} onChange={this.setMsg}></textarea>
                        <button className="btnAsLink leftMe1" onClick={this.sendMsg}>send</button>
                        <button className="btnAsLink" onClick={this.cancelSendMsg}>cancel</button>
                    </div>}
                    {this.props.isConnected && this.props.isShowAllData && 
                        <div id="eCommentsWrapper">
                            <div className="caption">comments</div>
                            <div className="eRevealIDWrapper"><input type="checkbox" id="eRevealIDBtn"/><span>reveal name and link to my card</span></div>
                            <textarea id="eCommentInput" placeholder="here is the place to share your thoughts on this activity." maxLength="1000"></textarea>
                            <div id="eCommentBtn" className="btn" onClick={this.sendComment}>comment</div>
                        </div>
                    }
                    {this.props.isConnected && this.props.isShowAllData && Helper.fetchActivityById(this.state.activity.id).comments && 
                            Helper.fetchActivityById(this.state.activity.id).comments.map((oComment,ii)=>{
                                let oElm=null;
                                if (((new Date()).getTime()-oComment.dt.toDate().getTime())/1000/60/60>48){
                                    oElm=<div className="commentWrapper" key={ii}>
                                    <div>{Helper.getMonthShortName(oComment.dt.toDate())+" "+oComment.dt.toDate().getDate()+", "+oComment.dt.toDate().getFullYear()}</div>
                                    <div className={oComment.userId ? "registeredUser" : ""} data-userid={oComment.userId ? oComment.userId : -1} onClick={this.openCommentMakerCard}>{oComment.userId ? oComment.userName ? oComment.userName : "runner" : "anonymous"}</div>
                                    <div>{oComment.comment}</div>
                                    </div>;
                                }
                                return oElm;

                            }
                        ) 

                    }
                    {this.props.isShowAllData && <div id="eChooseDatesBtn" className="btn" onClick={()=>this.props.isConnected ? this.chooseDates() : alert("you should login first")}>choose a date</div>}
                </div>
            )
        }
        else{
            return <div>nothing in activity card yet</div>
        }

    }
    openActivityPg(){
        this.props.setPage(ACTIVITY_PAGE);
        this.props.setSelectedActivityId(this.state.activity.id);
    }
    openSendMsgInterface(){
        this.setState({
            isOpenSendMsgInterface:true,
            msg:"",
        })
    }
    sendMsg(){
        const _self=this;
        if (!this.state.msg.trim()){
            alert ("write a msg first");
            return;
        }
        db.collection("runners").doc(this.state.activity.initiator).update({
            messagesReceived:firebase.firestore.FieldValue.arrayUnion({senderId:firebase.auth().currentUser.uid,msg:this.state.msg}),
            isShowNewMsgsAlert:true,
        })
        .then(()=>{
            Helper.sendEmail(this.props.oUser.name,_self.state.msg.trim(),Helper.fetchRunnerById(_self.state.activity.initiator).email);
            this.setState({
                isOpenSendMsgInterface:false,
            })
            alert ("message was successfully sent");
        })
        .catch((sErr)=>{
            console.error("an error in sendIM in activitycard component. error is - ",sErr);
        })
    }
    setMsg(e){
        this.setState({
            msg:e.target.value,
        })
    }
    cancelSendMsg(){
        this.setState({
            isOpenSendMsgInterface:false,
        })
    }
    sendChatRequest(){
        db.collection("runners").doc(this.state.activity.initiator).get()
        .then((doc)=>{
            switch(doc.data().chatState){
                case "free":
                    chatDbManager.updateChatData("chatRequest",this.state.activity.initiator,"");
                    break;
                default:
                    alert ("the runner in in a chat right now");
                    break;
            }
        })
        .catch((error)=>{
            console.log("error in sendChatReuqest. err is - "+error);
        })
    }
    fetchLanguages(arLanguages){
        let sLanguages="";
        arLanguages && arLanguages.forEach((oLanguage)=>{
            if (sLanguages!=="")sLanguages+=", ";
            sLanguages+=Helper.fetchLanguageMasteryById(oLanguage.mastery)+" "+Helper.fetchLanguageById(oLanguage.languageId);
        });
        return sLanguages;
    }
    componentDidMount(){
        const oActivity=Helper.fetchActivityById(this.props.activityId);
        const _self=this;
        this.props.fetchOneRunner(oActivity.initiator);
        this.setState({
            activity:oActivity,
//                languagesProcessed:this.fetchLanguages(oActivity.languages),
            cityName:Helper.fetchCityById(oActivity.city) ? Helper.fetchCityById(oActivity.city).city+" - "+Helper.fetchCityById(oActivity.city).country : "unknown",
        })

        if (oActivity && this.props.isShowAllData){
            oActivity.arActivityVisualItems.forEach((oItm,ii)=>{
                _self.getImgUrl(oItm.url);
            });
        }

    }
    componentDidUpdate(){
        if (!!Helper.fetchRunnerById(this.state.activity.initiator) && !this.state.initiatorMainImg){
            this.downloadImg(Helper.fetchRunnerById(this.state.activity.initiator).imgName,"initiatorMainImg");
            this.downloadHostVid();
        }
    }
/*    
    componentDidMount(){
        this.chkIfUpdate();
    }
    componentDidUpdate(){
        this.chkIfUpdate();
    }
    chkIfUpdate(){
        if (!this.state.activity){
            const oActivity=Helper.fetchActivityById(this.props.activityId);
            this.setState({
                activity:oActivity,
//                languagesProcessed:this.fetchLanguages(oActivity.languages),
                cityName:Helper.fetchCityById(oActivity.city) ? Helper.fetchCityById(oActivity.city).city+" - "+Helper.fetchCityById(oActivity.city).country : "unknown",
            })
        }
    }
*/    
    back(){
        this.props.setPage(this.props.prevPg);
    }
    downloadHostVid(){
        firebase.storage().ref().child(Helper.fetchRunnerById(this.state.activity.initiator).id+".mp4").getDownloadURL()
        .then((sUrl)=>{
            if (sUrl){
                this.setState({
                    isHostVid:true,
                    hostVidSrc:sUrl,
                })
            };
            console.log("downloadMovie succeeded");
        })
        .catch((error)=>{
            console.log("downloadingMovie in ProfileMaekr component failed. error is - ",error);
        });
    }
    downloadImg(sImgName,sStateParam){
        if (sImgName){
            firebase.storage().ref().child(sImgName).getDownloadURL()
            .then((sUrl)=>{
                if (sUrl){
                    let oChanges={};
                    oChanges[sStateParam]=sUrl;
                    this.setState(oChanges);
                };
                console.log("downloadImg succeeded");
            })
            .catch((error)=>{
                console.log("downloadingImg in ActivityCard component failed. error is - ",error);
            });
        }
    }
    editActivity(){
        this.props.setSelectedActivityId(this.state.activity.id);
        this.props.setPage(ACTIVITY_MAKER_PAGE);
    }
    chooseDates(){
        this.props.setPage(CHOOSE_DATES_PAGE,false);
    }
    sendComment(){
        let bIsCommentApproved=false;
        for (let sSchedActId in this.state.activity.oScheduledAct){
            let oScheduledActDt=!!this.state.activity.oScheduledAct[sSchedActId].dt.getTime ? this.state.activity.oScheduledAct[sSchedActId].dt : this.state.activity.oScheduledAct[sSchedActId].dt.toDate();  
           if (oScheduledActDt.getTime()<(new Date()).getTime()){
               for (let sUserId in this.state.activity.oScheduledAct[sSchedActId]){
                   if (sUserId===firebase.auth().currentUser.uid && parseInt(this.state.activity.oScheduledAct[sSchedActId][sUserId].num,10)>0){
                       bIsCommentApproved=true;
                       break;
                   }
               }
           } 
        }
        if (!this.state.isCommentSent && bIsCommentApproved){
            if (this.state.activity.initiator!==firebase.auth().currentUser.uid || document.getElementById("eRevealIDBtn").checked){
                const oComment={userId:document.getElementById("eRevealIDBtn").checked ? firebase.auth().currentUser.uid : "",userName:Helper.fetchRunnerById(firebase.auth().currentUser.uid).name ? Helper.fetchRunnerById(firebase.auth().currentUser.uid).name : "",comment:document.getElementById("eCommentInput").value,dt:new Date()};
                db.collection("activities").doc(this.props.activityId).update({
                    comments:firebase.firestore.FieldValue.arrayUnion(oComment),
                })
                .then(()=>{
/*                    alert (JSON.stringify(oComment));*/
                    alert ("thank you for your comment");
                })
                .catch((oErr,sErr1,sErr2)=>{
                    console.error("an erro in sendComment in activityCard in . error is - ",sErr1+" - "+sErr2);
                })
                this.setState({
                    isCommentSent:true,
                })
        
            }
            else{
                alert ("you can comment on your activity only if you reveal your id (check the reveal name and link to my card btn)");
            }
        }
    }
    openCommentMakerCard(e){
        if (e.target.getAttribute("data-userid") && e.target.getAttribute("data-userid")!=="-1"){
            this.props.setSelectedRunnerId(e.target.getAttribute("data-userid"));
            this.props.setPage(RUNNER_PAGE);
        }
    }
    getImgUrl(sImgName){
        if (!!sImgName){
            firebase.storage().ref().child(sImgName).getDownloadURL()
            .then((sUrl)=>{
                if (sUrl){
                    let arImages=this.state.arImages.slice();
                    arImages.push({original:sUrl,thumbnail:sUrl,});
                    this.setState({
                        arImages,
                    })
                };
            })
            .catch((error)=>{
                console.log("getImgUrl in ActivityMaker component failed. error is - ",error);
            });
        }
    }
}

const mapStateToProps = (state)=>{
    return {
        activities:state.activities.activities,
        cities:state.cities.cities,
        connected:state.runners.connected,
        isConnected:state.upState.isConnected,
        languages:state.languages.languages,
        prevPg:state.upState.prevPg,
        fetchOneRunnerState:state.runners.action,
        oUser:state.upState.user,
    }
}
const mapDispatchToProps = (dispatch)=>{
    return {
        setPage:(pgId)=>{dispatch(setPage(pgId))},
        fetchOneRunner:(runnerId)=>{dispatch(fetchOneRunner(runnerId))},
        setSelectedActivityId:(activityId)=>{dispatch(setSelectedActivityId(activityId))},
        setSelectedRunnerId:(sRunnerId)=>{dispatch(setSelectedRunnerId(sRunnerId))},
    }
}



export default connect(mapStateToProps,mapDispatchToProps)(ActivityCard);