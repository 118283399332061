
import {NONE,FETCH_LANGUAGES_SUCCESS,FETCH_LANGUAGES_ERROR} from "../../constants.js";
const initialState = {
    action:NONE,
    languages:[],
}


const languagesReducer = (state=initialState,{type,languages})=>{
    switch (type){
        case FETCH_LANGUAGES_SUCCESS:
            return {
                ...state,
                action:type,
                languages,
            }
        case FETCH_LANGUAGES_ERROR:
            return {
                ...state,
                action:type,
            }
        default:
            return state;
    }
}

export default languagesReducer;