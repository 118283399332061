
import {NONE,FETCH_ACTIVITYSCOPE_SUCCESS,FETCH_ACTIVITYSCOPE_ERROR} from "../../constants.js";
const initialState = {
    action:NONE,
    activityScope:[],
}


const activityScopeReducer = (state=initialState,{type,activityScope})=>{
    switch (type){
        case FETCH_ACTIVITYSCOPE_SUCCESS:
            return {
                ...state,
                action:type,
                activityScope,
            }
        case FETCH_ACTIVITYSCOPE_ERROR:
            return {
                ...state,
                action:type,
            }
        default:
            return state;
    }
}

export default activityScopeReducer;