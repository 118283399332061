import React,{Component} from "react";
import {connect} from "react-redux";
import {fetchCities} from "../redux/actions/cities";
import {fetchLanguages} from "../redux/actions/languages";
import {fetchActivityScope} from "../redux/actions/activityScope";
import {fetchActivityType} from "../redux/actions/activityType";
import {fetchAvailability} from "../redux/actions/availability";
import {fetchLanguageMastery} from "../redux/actions/languageMastery";
import {fetchLocation} from "../redux/actions/location";
import {setPage} from "../redux/actions/state";
import {addConnected,removeConnected} from "../redux/actions/runners";
import Appetizer from "./Appetizer";
import RunnerPage from "./RunnerPage";
import ActivityCard from "./ActivityCard";
import About from "./About.js";
import ActivityMaker from "./ActivityMaker";
import Login from "./Login";
import Signup from "./Signup";
import OpenSesamiInterface from "./OpenSesamiInterface";
import City from "./City";
import ProfileMaker from "./ProfileMaker";
import Chat from "./Chat";
import ActivitiesScheduler from "./ActivitiesScheduler";
import Footer from "./Footer";
import "../index.css";
import "../styles/normalize.css";
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import chatDbManager from "../chatDbManager";
import firebase from "firebase";
import {ABOUT_PAGE,ACTIVITY_PAGE,APPETIZER_PAGE,CITY_PAGE,RUNNER_PAGE,LOGIN_PAGE,SIGNUP_PAGE,PROFILE_PAGE,CHAT_PAGE,FETCH_CITY_SUCCESS,FETCH_LANGUAGES_SUCCESS,ACTIVITY_MAKER_PAGE,SET_DATES_PAGE,CHOOSE_DATES_PAGE} from "../constants";

class App extends Component{

    constructor(props){
        super(props);
    }
    render(){
        let eSelectedElement=null;
        switch (this.props.selectedPg){
            case APPETIZER_PAGE:
                eSelectedElement=<Appetizer/>;
                break;
            case CITY_PAGE:
                eSelectedElement=<City/>;
                break;
            case RUNNER_PAGE:
                eSelectedElement=<RunnerPage runnerId={this.props.selectedRunnerId}/>;
                break;
            case ACTIVITY_PAGE:
                eSelectedElement=<ActivityCard activityId={this.props.selectedActivityId} isShowAllData="1" />;
                break;
            case ACTIVITY_MAKER_PAGE:
                eSelectedElement=<ActivityMaker/>;
                break;
            case LOGIN_PAGE:
                eSelectedElement=<Login/>;
                break;
            case SIGNUP_PAGE:
                eSelectedElement=<Signup/>;
                break;
            case PROFILE_PAGE:
                eSelectedElement=<ProfileMaker/>
                break;
            case CHAT_PAGE:
                eSelectedElement=<Chat/>
                break;
            case SET_DATES_PAGE:
                eSelectedElement=<ActivitiesScheduler/>
                break;
            case CHOOSE_DATES_PAGE:
                eSelectedElement=<ActivitiesScheduler type="choose"/>
                break;
            case ABOUT_PAGE:
                eSelectedElement=<About/>;
                break;
            default:
                eSelectedElement=<div>couldn't find the address</div>;
        }
        return (
            this.props.citiesUploadState===FETCH_CITY_SUCCESS && this.props.languagesUploadState===FETCH_LANGUAGES_SUCCESS && <div id="eApp">
                <div id="eHeader">{this.props.selectedPg!=LOGIN_PAGE && this.props.selectedPg!=SIGNUP_PAGE && <OpenSesamiInterface/>}</div>
                <div id="eMain">{eSelectedElement}</div>
                <div id="eFooter"><Footer/></div>
            </div>
        )
    }   
    componentDidMount(){
        this.props.fetchCities();
        this.props.fetchLanguages();
        this.props.fetchActivityScope();
        this.props.fetchActivityType();
        this.props.fetchAvailability();
        this.props.fetchLanguageMastery();
        this.props.fetchLocation();
/*        this.trackRunnersOnlineState();*/
    }
    trackRunnersOnlineState(){
        const self=this;
        firebase.firestore().collection('status')
        .where('state', '==', 'online')
        .onSnapshot(function(snapshot) {
            snapshot.docChanges().forEach(function(change) {
                if (change.type === 'added') {
                    var msg = 'User ' + change.doc.id + ' is online.';
                    self.props.addConnected(change.doc.id);
                    // ...
                }
                if (change.type === 'removed') {
                    var msg = 'User ' + change.doc.id + ' is offline.';
                    self.props.removeConnected(change.doc.id);

                    if (self.props.chatterId===change.doc.id){
                        chatDbManager.updateChatData("endChat",firebase.auth().currentUser.uid,"");
                        self.props.setPage(self.props.prevPg);
                    }

                    // ...
                }
            });
        });
    }

}

const mapStateToProps = (state) => {
    return {
        selectedPg:state.upState.pg,
        selectedCityId:state.upState.selectedCityId,
        selectedRunnerId:state.upState.selectedRunnerId,
        selectedActivityId:state.upState.selectedActivityId,
        citiesUploadState:state.cities.action,
        languagesUploadState:state.languages.action,
        chatterId:state.chat.chatterId,
        prevPg:state.upState.prevPg,
/*
        action:state.cities.action,
        cities:state.cities.cities,
*/        
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchCities:()=>dispatch(fetchCities()),
        fetchLanguages:()=>dispatch(fetchLanguages()),
        fetchAvailability:()=>dispatch(fetchAvailability()),
        fetchActivityScope:()=>dispatch(fetchActivityScope()),
        fetchActivityType:()=>dispatch(fetchActivityType()),
        fetchLanguageMastery:()=>dispatch(fetchLanguageMastery()),
        fetchLocation:()=>dispatch(fetchLocation()),
        addConnected:(connectedId)=>dispatch(addConnected(connectedId)),
        removeConnected:(connectedId)=>dispatch(removeConnected(connectedId)),
        setPage:(sPg)=>dispatch(setPage(sPg)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(App);
